import { app as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }

    static getTariffs(filter, limit, offset) {
        let params = Object.assign({}, filter, {
            offset: offset,
            limit: limit
        })
        return this._get(`booking/tariff`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить тарифы');
        });
    }

    static getRooms(filter, limit, offset) {
        let params = Object.assign({}, filter, {
            offset: offset,
            limit: limit
        })
        return this._get(`hotel/space`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить номера');
        });
    }

    static getRoom(id) {
        let params = Object.assign({}, id)
        return this._get(`hotel/space/${id}`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить номер');
        });
    }

    static getPromo(code) {
        return this._get(`booking/promo/${code}`, null,  null).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось проверить промокод');
        });
    }

    static getAmount(params) {
        return this._post(`booking/get_amount`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось проверить стоимость корзины');
        });
    }

    static getFreeRooms(filter, limit, offset) {
        let params = Object.assign({}, filter, {
            offset: offset,
            limit: limit
        })
        return this._post(`booking/find_free_rooms_with_amount`, null, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить номера');
        });
    }

    static getTariffRoom(params) {
        return this._post(`booking/find_free_rooms_with_amount_detail`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить номер');
        });
    }

    static getCalendarRoom(params) {
        return this._post(`booking/get_room_calendar`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данных по номеру');
        });
    }

    static getOffers(params) {
        return this._get(`booking/offers`, null, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить спец.предложения');
        });
    }

    static submitOrder(params) {
        return this._post(`booking/get_book_rooms`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать бронь');
        });
    }

    static changeOrderState(params) {
        return this._post(`booking/change_booking_state`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось изменить статус заказа');
        });
    }

    static getPaymentInfo(params) {
        return this._post(`banking/payments_info`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить способы оплаты');
        });
    }

    static submitPaymentSber(params) {
        return this._post(`banking/set_payment`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось отправить запрос на оплату');
        });
    }

    static statusPaymentSber(params) {
        return this._post(`banking/get_status`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось проверить статус оплаты');
        });
    }

    static submitPaymentMir(params) {
        return this._post(`banking/mir/set_payment`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось отправить запрос на оплату');
        });
    }

    static statusPaymentMir(params) {
        return this._post(`banking/mir/get_status`, null,  params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось проверить статус оплаты');
        });
    }
}
