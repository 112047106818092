<template>
    <div
        class="app__header"
        :class="step ? 'm--steps' : ''"
    >
        <button
            v-if="back"
            class="button m--blue m--small m--uppercase app__header-back"
            :class="loaderBack ? '' : 'm--icon m--icon-back'"
            :disabled="loaderBack ? 1 : 0"
            @click="this.$emit('prev')"
        >
            <svg
                v-if="loaderBack"
                class="spinner"
                viewBox="0 0 50 50"
            >
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
            <span>
                {{ back }}
            </span>
        </button>
        <h1 class="app__header-title h1">
            {{ title }}
        </h1>
        <button
            v-if="!hideClose"
            class="button m--blue-outline m--small m--uppercase m--icon m--icon-close app__header-close"
            @click="close()"
        >
            <span>
                {{ isIframe ? 'Закрыть страницу бронирования' : 'Сбросить' }}
            </span>
        </button>
        <div
            v-if="steps"
            class="app__header-steps"
            :class="'m--step-' + step"
        >
            <div
                v-for="index in steps"
                class="app__header-step"
                :class="(index <= step) ? 'm--step-' + index + ' m--active' : 'm--step-' + index"
            >
                {{ index }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            title: {
                type: String,
                default() { return ''; }
            },
            back: {
                type: String,
                default() { return ''; }
            },
            loaderBack: {
                type: Boolean,
                default() { return false; }
            },
            hideClose: {
                type: Boolean,
                default() { return false; }
            },
            step: {
                type: Number,
                default() { return null; }
            },
            steps: {
                type: Number,
                default() { return null; }
            },
        },
        data: function() {
            return {
                isIframe: parent != self
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            close() {
                this.$store.dispatch('clearOrder');
                if (this.isIframe) {
                    window.parent.postMessage('close', '*');
                } else {
                    this.$router.push({ name: 'index' });
                }
            }
        }
    };
</script>
