const order = {
    orderRoomPrice: (item) => {
        let priceRoom = 0;
        // Маразм от 1С ника. В пакетах считаются только взрослые + подростки.
        // let peoples = item.peoples.people + item.peoples.teen + item.peoples.children;
        let peoples = item.peoples.people + item.peoples.teen;
        //let peoples = item.peoples.people;
        let tariff = item.tariff;
        let listPrice = tariff.list_price;
        let placementAmount = listPrice.placement_amount;
        console.log(listPrice, placementAmount);
        let packages = placementAmount.package;
        let peoplesInPack = [];
        for (let key of Object.keys(packages)) {
            // If для проверки доп.мест с меньшим пакетом. Потом убрать
            // if (key * 1 < 9) {
            console.log(key + " -> " + packages[key]);
            peoplesInPack.push(key * 1);
            // }
        }
        peoplesInPack.reverse();
        console.log(peoplesInPack);
        let packageSelected = null;
        if (peoples <= peoplesInPack[peoplesInPack.length - 1]) {
            packageSelected = peoplesInPack[peoplesInPack.length - 1];
        } else {
            peoplesInPack.every(item => {
                if (peoples >= item) {
                    packageSelected = item;
                    return false;    
                }
                return true;
            });
        }
        console.log('Пакет: ', packageSelected);
        console.log('Базовая вместимость: ', listPrice.place_count);
        priceRoom = packages[packageSelected];
        // Маразм от 1С ника. А теперь все.
        peoples = item.peoples.people + item.peoples.teen + item.peoples.children;
        if (peoples > packageSelected) {
            let baseCount = packageSelected;
            let extraCount = packageSelected > listPrice.place_count ? packageSelected : listPrice.place_count;
            /* Расселение взрослых */
            baseCount = baseCount - item.peoples.people;
            extraCount = extraCount - item.peoples.people;
            //console.log('baseCount:', baseCount);
            //console.log('extraCount:', extraCount);
            if (baseCount < 0) {
                if (baseCount < extraCount) {
                    if (extraCount < 0) {
                        baseCount = baseCount - extraCount;
                    }
                    console.log('Adult', -1 * baseCount);
                    priceRoom = priceRoom + (-1) * baseCount * placementAmount.adult;
                }
                baseCount = 0;
            }
            if (extraCount < 0) {
                console.log('Adult Extra', -1 * extraCount);
                priceRoom = priceRoom + (-1) * extraCount * placementAmount.extra_adult;
                extraCount = 0;
            }
            /* Расселение подростков */
            baseCount = baseCount - item.peoples.teen;
            extraCount = extraCount - item.peoples.teen;
            //console.log('baseCount:', baseCount);
            //console.log('extraCount:', extraCount);
            if (baseCount < 0) {
                if (baseCount < extraCount) {
                    if (extraCount < 0) {
                        baseCount = baseCount - extraCount;
                    }
                    console.log('Teen', -1 * baseCount);
                    priceRoom = priceRoom + (-1) * baseCount * placementAmount.teen;
                }
                baseCount = 0;
            }
            if (extraCount < 0) {
                console.log('Teen Extra', -1 * extraCount);
                priceRoom = priceRoom + (-1) * extraCount * placementAmount.extra_teen;
                extraCount = 0;
            }
            /* Расселение детей */
            baseCount = baseCount - item.peoples.children;
            extraCount = extraCount - item.peoples.children;
            //console.log('baseCount:', baseCount);
            //console.log('extraCount:', extraCount);
            if (baseCount < 0) {
                if (baseCount < extraCount) {
                    if (extraCount < 0) {
                        baseCount = baseCount - extraCount;
                    }
                    console.log('Children', -1 * baseCount);
                    priceRoom = priceRoom + (-1) * baseCount * placementAmount.children;
                }
                baseCount = 0;
            }
            if (extraCount < 0) {
                console.log('Children Extra', -1 * extraCount);
                priceRoom = priceRoom + (-1) * extraCount * placementAmount.extra_children;
                extraCount = 0;
            }
        }
        console.log('priceRoom', priceRoom);
        return priceRoom;
    },
    orderAmount: (order) => {
        let amount = 0;
        let amountOtions = 0;
        order.items.forEach(item => {
            amount = amount + item.price * 1;
            item.options.forEach(option => {
                amountOtions = amountOtions * 1 + (option.value * 1) * (option.count * 1);
            });
        });
        if (order.offer) {
            amount = amount - Math.ceil(amount * (order.offer.percent / 100));
        } else if (order.promocode) {
            amount = amount - Math.ceil(amount * (order.promocode.percent / 100));
        }
        amount = amount + amountOtions;
        console.log('orderAmount', amount);
        return amount;
    }
}

export default {
    install (app, options) {
        app.order = order
        app.config.globalProperties.$order = order
    }
}
