<template>
    <div 
        class="rooms__item" 
        ref="itemRoom"
    >
        <div class="rooms__item-inner">
            <div 
                class="rooms__item-picture"
                :class="room.free_rooms.length === 0 ? 'm--disabled' : ''"
            >
                <div class="rooms__item-picture-inner">
                    <Splide
                        v-if="room.picture || room.photos.length"
                        :options="splideOptions"
                    >
                        <SplideSlide
                            :key="`${room.id}-pic-0`"
                        >
                            <img 
                                :src="room.picture" 
                                alt="" 
                            />
                        </SplideSlide>
                        <SplideSlide
                            v-for="(photo, index) in room.photos"
                            :key="`${room.id}-pic-${index + 1}`"
                        >
                            <img 
                                :data-splide-lazy="photo" 
                                alt="" 
                            />
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
            <div
                v-if="room.free_rooms.length === 0"
                class="rooms__item-label"
            >
                Номера распроданы
            </div>
            <div
                v-else-if="(room.free_rooms.length === 1 && room.not_last === false)"
                class="rooms__item-label m--last"
            >
                Остался последний
            </div>
            <div class="rooms__item-info">
                <div class="rooms__item-name">{{ room.name }}</div>
                <div class="rooms__item-subname">
                    {{ room.sub_name || '&nbsp;' }}
                    <div 
                        v-if="room.old_price && room.price"
                        class="rooms__item-price m--old"
                    >
                        <span>{{ $helpers.toPrice(room.price + (room.price * room.old_price / 100)) }}</span> <span class="m--currency">₽</span>
                    </div>
                </div>
                <div class="rooms__item-info-block">
                    <div class="rooms__item-params">
                        <div class="rooms__item-param m--area">{{ room.area }} кв.м</div>
                        <div class="rooms__item-param m--persons">до {{ $helpers.stringForNumber((room.place_count + room.extra_place_count), ['человека', 'человек', 'человек']) }}</div>
                        <div 
                            v-if="room.gift_spaces"
                            :class="['rooms__item-param', `m--${room.gift_spaces.class_name}`]"
                        >
                            {{ room.gift_spaces.text }}
                        </div>
                    </div>
                    <div class="rooms__item-prices">
                        <div class="rooms__item-price m--new">от <span>{{ $helpers.toPrice(room.price || 0) }}</span> <span class="m--currency">₽</span></div>
                        <div class="rooms__item-included">{{ room.included }}</div>
                    </div>
                </div>
                <div class="rooms__item-info-block">
                    <button 
                        class="button rooms__item-button m--blue-outline"
                        :class="opened ? 'is-active' : ''"
                        @click.prevent="toggleMoreInfo(id)"
                    >
                        Подробнее
                    </button>
                    <button
                        v-if="button === 'order'"
                        class="button rooms__item-button m--blue"
                        :class="basket ? 'is-active' : ''"
                        :disabled="room.free_rooms.length === 0 ? 1 : 0"
                        @click.prevent="addToBasket(room.id)"
                    >
                        {{ basket ? 'Выбран' : 'Забронировать' }}
                    </button>
                    <template
                        v-else
                    >
                        <button
                            v-if="!basket || room.free_rooms.length < 2"
                            class="button rooms__item-button m--blue"
                            :class="basket ? 'is-active' : ''"
                            :disabled="room.free_rooms.length === 0 ? 1 : 0"
                            @click.prevent="addToBasket(room.id)"
                        >
                            {{ basket ? 'В корзине' : 'В корзину' }}
                        </button>
                        <div
                            v-else
                            class="button rooms__item-button m--blue-outline m--counter"
                        >
                            <button
                                class="button rooms__item-button-counter m--blue m--minus"
                                @click.prevent="changeInBasket(room.id, -1)"
                            >
                                -
                            </button>
                            <div 
                                class="rooms__item-button-number"
                            >
                                {{ count }}
                            </div>
                            <button
                                class="button rooms__item-button-counter m--blue m--plus"
                                :disabled="room.free_rooms.length === count ? 1 : 0"
                                @click.prevent="changeInBasket(room.id, 1)"
                            >
                                +
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    export default {
        components: {
            Splide,
            SplideSlide,
        },
        props: {
            id: {
                type: Number,
                default() { return null; }
            },
            room: {
                type: Object,
                default() { return null; }
            },
            opened: {
                type: Boolean,
                default() { return false; }
            },
            basket: {
                type: Boolean,
                default() { return false; }
            },
            count: {
                type: Number,
                default() { return 0; }
            },
            button: {
                type: String,
                default() { return null; }
            }
        },
        data: function() {
            return {
                splideOptions: {
                    arrows: true,
                    lazyLoad: 'nearby'
                    //fixedHeight: '100%',
                    //cover: true
                }
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            toggleMoreInfo(id) {
                let params = {
                    id: (this.opened) ? null : id,
                    refItem: this.$refs.itemRoom
                }
                this.$emit('moreInfo', params);
            },
            addToBasket(id) {
                if (this.button === 'order') {
                    this.$emit('setToBasket', id);
                    this.$emit('next');
                } else {
                    this.$emit('addToBasket', id);
                }
            },
            changeInBasket(id, direction) {
                let params = {
                    id: id,
                    direction: direction
                }
                this.$emit('changeInBasket', params);
            },
            select() {
                let params = {
                    index: this.index,
                    data: {
                        persons: this.persons.value,
                        childs: this.childs.value,
                        kids: this.kids.value,
                    }
                }
                this.$emit('changeRoomData', params);
            },
            remove() {
                let params = {
                    index: this.index,
                }
                this.$emit('deleteRoomData', params);
            },
        }
    };
</script>
