import { createRouter, createWebHashHistory  } from 'vue-router';
import { store } from '@/store/store'
import index from '@/views/index.vue';
import admin from '@/views/admin.vue';
import basket from '@/views/basket.vue';
import basketTariffs from '@/views/basket-tariffs.vue';
import order from '@/views/order.vue';
import payment from '@/views/payment.vue';
import resultCashless from '@/views/result-cashless.vue';
import resultMir from '@/views/result-mir.vue';
import resultSber from '@/views/result-sber.vue';
import rooms from '@/views/rooms.vue';
import tariffs from '@/views/tariffs.vue';

const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        meta: { title: 'Данные бронирования.' }
    }, {
        path: '/admin/',
        name: 'admin',
        component: admin,
        meta: { title: 'Быстрый поиск.' }
    }, {
        path: '/rooms/',
        name: 'rooms',
        component: rooms,
        meta: { title: 'Выбор номеров.' }
    }, {
        path: '/tariffs/',
        name: 'tariffs',
        component: tariffs,
        meta: { title: 'Выбор тарифа.' }
    }, {
        path: '/basket/',
        name: 'basket',
        component: basket,
        meta: { title: 'Корзина. Шаг 1.' }
    }, {
        path: '/basket-tariffs/',
        name: 'basket-tariffs',
        component: basketTariffs,
        meta: { title: 'Корзина. Шаг 2.' }
    }, {
        path: '/order/',
        name: 'order',
        component: order,
        meta: { title: 'Данные гостей.' }
    }, {
        path: '/payment/',
        name: 'payment',
        component: payment,
        meta: { title: 'Оплата брони.' }
    }, {
        path: '/rsult-cashless/',
        name: 'result-cashless',
        component: resultCashless,
        meta: { title: 'Результаты бронирования.' },
        props: true,
    }, {
        path: '/result-mir/',
        name: 'result-mir',
        component: resultMir,
        meta: { title: 'Результаты бронирования.' },
        props: true,
    }, {
        path: '/result-sber/',
        name: 'result-sber',
        component: resultSber,
        meta: { title: 'Результаты бронирования.' },
        props: true,
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: 'is-subactive',
    linkExactActiveClass: 'is-active',
    routes
});

export default router;
