<template>
    <div class="app">
        <router-view />
        <app-error />
    </div>
</template>

<script>
    import appError from '@/components/app-error.vue';
    //import appLoader from '@/components/app-loader.vue';

    export default {
        components: {
            appError,
            //appLoader
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        },
        watch: {
          $route: {
            immediate: true,
            handler(to) {
              document.title = to.meta.title + ' Крутики. Бронирование номеров';
            }
          },
        },
        created() {
            window.onerror = (message, source, lineno, colno, err) => {
                console.error('Window error', err.message);
                this.$store.dispatch('showError', { err });
            };
        },
        errorCaptured(err, vm, info) {
            console.error('Local error', err.message, vm, info);
            this.$store.dispatch('showError', { err });
            return false;
        }
    };
</script>

<style lang="postcss">
    @import './assets/css/main.pcss';
</style>
