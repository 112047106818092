<template>
    <div
        ref="topPage"
        class="app__block m--fixed-footer"
    >
        <Header
            :step="3"
            :steps="3"
            :title="'Оплата брони'"
            :back="'Отменить бронирование'"
            :loaderBack="loaderBack"
            @prev="prev"
        />
        <div class="app__body order">
            <div class="order__block">
                <div class="order__payments">
                    <div
                        class="order__block-title h2"
                    >
                        Ваша бронь подтверждена.
                    </div>
                    <div class="order__payments-content content">
                        <h3>Код брони: <span class="color-blue">{{ order.id }}</span>. Итого к оплате: <span class="color-blue">{{ $helpers.toPrice(order.amount || 0) }} ₽</span>.</h3>
                        <h3>Внимание! Бронь будет активна в течение 20 минут. Если Вы не оплатите заказ, бронь будет снята.</h3>
                    </div>
                    <div class="order__payments-content content">
                        <div class="form__field field">
                            <div class="field__input">
                                <input
                                    v-model="agree"
                                    id="agree"
                                    name="agree"
                                    type="checkbox"
                                    class="checkbox"
                                >
                                <label for="agree">Фактом бронирования вы соглашаетесь с <a href="https://krutiki.ru/privacy-policy/" target="_blank">политикой конфиденциальности</a> и <a href="https://krutiki.ru/porjadok-annuljacii-zakaza-v-kurortnom-otele-krutiki-turgojak/" target="_blank">правилами отмены бронирования</a>.</label>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="$store.state.loader"
                        class="order__payments-block"
                    >
                        <Loader />
                    </div>
                    <div
                        v-else
                        class="order__payments-block"
                    >
                        <div
                            v-for="(payment, index) in paymentList"
                            :key="`payment-${index}`"
                            class="order__payments-item"
                        >
                            <div class="order__block-title h2">
                                {{ payment.name }}
                                <div
                                    v-if="payment.is_rostour"
                                    class="order__payments-item-label"
                                >
                                    Получите кэшбэк по карте мир
                                </div>
                            </div>
                            <a
                                v-if="payment.is_rostour"
                                class="order__payments-item-registration button m--blue-outline m--small"
                                href="https://privetmir.ru/rostourism/register/"
                                data-fancybox
                                data-type="iframe"
                                data-preload="false"
                            >
                                Зарегистрироваться
                            </a>
                            <div class="order__payments-item-block">
                                <div
                                    class="order__payments-item-content content"
                                    v-html="payment.content_public"
                                />
                                <button
                                    class="order__payments-item-button button m--blue m--small"
                                    :disabled="!agree || (disableSubmit || payment.disabled) ? 1 : 0"
                                    @click.prevent="submitPayment(payment.method)"
                                >
                                    <svg
                                        v-if="loaderSubmit[payment.method]"
                                        class="spinner"
                                        viewBox="0 0 50 50"
                                    >
                                        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                                    </svg>
                                    {{ payment.button }}
                                </button>
                            </div>
                            <div
                                v-if="payment.alert_content"
                                class="order__payments-item-alert"
                                v-html="payment.alert_content"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="order__notice">
                <div class="order__notice-title">
                    Гарантии безопасности
                </div>
                <div class="order__notice-content content">
                    <p>Ввод данных и обработка платежа банковской картой проходит на защищённой странице процессинговой системы, которая прошла международную сертификацию. Ваши конфиденциальные данные полностью защищены. Никто, в том числе система бронирования, не может их получить. При работе с конфиденциальными данными применяется стандарт защиты информации, созданный платёжными системами Visa и MasterCard — PCI DSS. Технология передачи данных гарантирует безопасность за счёт использования протоколов шифрования и технологии 3-D Secure. Возврат денег производится на карту, с которой был произведён платёж.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Loader from '@/components/loader.vue';
    import Header from '@/components/header';
    import { Fancybox } from "@fancyapps/ui";
    export default {
        components: {
            Loader,
            Header,
        },
        data() {
            return {
                agree: false,
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru') || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru') || new Date(),
                    },
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    offer: this.$store.state.offer,
                    id: this.$store.state.id,
                    amount: this.$store.state.amount,
                },
                paymentList: [],
                checkedAmount: null,
                customer: this.$store.state.customer || {},
                loaderSubmit: {},
                disableSubmit: false,
                loaderBack: false,
            };
        },
        created() {
            this.getPaymentList();
        },
        destroyed() {
        },
        mounted() {
            this.$refs.topPage.scrollIntoView();
        },
        methods: {
            getPaymentList() {
                console.log('getPaymentList');
                let params = {
                    date_from: this.order.range.start,
                    date_to: this.order.range.end,
                };
                this.$store.dispatch('showLoader', { label: 'Получение способов оплаты' });
                app.getPaymentInfo(params).then(res => {
                    this.paymentList = res;
                    this.$store.dispatch('hideLoader');
                }).catch(err => {
                    this.$store.dispatch('hideLoader');
                });
            },
            submitPayment(type) {
                this.disableSubmit = true;
                this.loaderSubmit[type] = true;
                console.log('reachGoal');
                this.$metrika.reachGoal('oplatitmodul');
                if (type === 'sberbank') {
                    let result = this.$router.resolve({
                        name: 'result-sber',
                        params: {
                            order_id: this.order.id
                        },
                    });
                    let url = window.location.protocol + '//' + window.location.host + '/' + result.href;
                    let params = {
                        amount: 1 * this.order.amount,
                        order_id: this.order.id,
                        callback: url,
                        failurl: url
                    };
                    app.submitPaymentSber(params).then(res => {
                        this.$store.dispatch('hideLoader');
                        this.disableSubmit = false;
                        this.loaderSubmit[type] = false;
                        console.log(res);
                        if (res.error) {
                            this.$store.dispatch('showError', {err: { message: '[' + res.error + '] ' + res.detail }});
                        } else {
                            if (res.formUrl && res.orderId) {
                                window.location.href = res.formUrl;
                            }
                        }
                    }).catch(err => {
                        this.$store.dispatch('hideLoader');
                        this.$store.dispatch('showError', { err });
                        this.disableSubmit = false;
                        this.loaderSubmit[type] = false;
                        console.error(err);
                    });
                } else if (type === 'rostour_mir') {
                    let result = this.$router.resolve({
                        name: 'result-mir',
                        params: {
                            order_id: this.order.id
                        },
                    });
                    let url = window.location.protocol + '//' + window.location.host + '/' + result.href;
                    let params = {
                        amount: 1 * this.order.amount,
                        order_id: this.order.id,
                        callback: url,
                        failurl: url
                    };
                    app.submitPaymentMir(params).then(res => {
                        this.$store.dispatch('hideLoader');
                        this.disableSubmit = false;
                        this.loaderSubmit[type] = false;
                        console.log(res);
                        if (res.error) {
                            this.$store.dispatch('showError', {err: { message: '[' + res.error + '] ' + res.detail }});
                        } else {
                            if (res.formUrl && res.orderId) {
                                window.location.href = res.formUrl;
                            }
                        }
                    }).catch(err => {
                        this.$store.dispatch('hideLoader');
                        this.$store.dispatch('showError', { err });
                        this.disableSubmit = false;
                        this.loaderSubmit[type] = false;
                        console.error(err);
                    });
                } else if (type === 'cashless') {
                    this.$router.push({ name: 'result-cashless' });
                }
            },
            prev() {
                let params = {
                    order_id: this.order.id,
                    order_status: 0
                }
                this.loaderBack = true;
                app.changeOrderState(params).then(res => {
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('updateOrderId', null);
                    this.loaderBack = false;
                    console.log(res);
                    this.$router.push({ name: 'order' });
                }).catch(err => {
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                    this.loaderBack = false;
                    console.error(err);
                });
            },
            next(params) {
                this.$router.push({ name: 'result', params: params });
            },
        }
    };
</script>
