import { createStore } from 'vuex';
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
    key: 'krutiki',
    storage: window.sessionStorage 
});

const roomTemplate = () => {
    return {
        people: 1,
        children: null,
        teen: null,
        rooms: 1
    };
};

const guestTemplate = () => {
    return {
        lname: null,
        fname: null,
        mname: null,
        birthday: null,
        pasport_number: null,
        pasport_series: null
    };
};

const getDefaultState = () => {
    let dateStart = new Date();
    let dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate() + 1);
    return {
        dateStart: dateStart,
        dateEnd: dateEnd,
        data: roomTemplate(),
        items: [],
        amount: 0,
        id: null,
        guests: [],
        customer: {},
        offer: null
    };
};

const store = createStore({
    state: getDefaultState(),
    plugins: [vuexPersist.plugin],
    mutations: {
        UPDATE_DATA(state, data) {
            state.data = data;
        },
        SET_DATE_START(state, dateStart) {
            state.dateStart = dateStart;
        },
        SET_DATE_END(state, dateEnd) {
            state.dateEnd = dateEnd;
        },
        ADD_GUEST(state) {
            state.guests.push(guestTemplate());
        },
        UPDATE_GUESTS(state, guests) {
            state.guests = guests;
        },
        CLEAR_ORDER(state) {
            Object.assign(state, getDefaultState());
        },
        ADD_ORDER_ITEM(state, roomId) {
            state.items.push(roomId);
        },
        UPDATE_ORDER_ITEMS(state, items) {
            state.items = items;
        },
        UPDATE_ORDER_ID(state, id) {
            state.id = id;
        },
        UPDATE_ORDER_AMOUNT(state, amount) {
            state.amount = amount;
        },
        UPDATE_CUSTOMER(state, customer) {
            state.customer = customer;
        },
        UPDATE_ROOMS(state, rooms) {
            state.rooms = rooms;
        },
        UPDATE_OFFER(state, offer) {
            state.offer = offer;
        },
        ERROR(state, error) {
            state.error = error;
        },
        LOADER(state, loader) {
            state.loader = loader;
        }
    },
    actions: {
        updateDate(context, range) {
            context.commit('SET_DATE_START', range.start);
            context.commit('SET_DATE_END', range.end);
        },
        updateData(context, params) {
            context.commit('SET_DATE_START', params.date_start);
            context.commit('SET_DATE_END', params.date_end);
            context.commit('UPDATE_DATA', params.data);
        },
        addGuest(context) {
            context.commit('ADD_GUEST');
        },
        updateGuests(context, params) {
            context.commit('UPDATE_GUESTS', params.guests);
        },
        clearOrder(context) {
            context.commit('CLEAR_ORDER');
        },
        addOrderItem(context, roomId) {
            context.commit('ADD_ORDER_ITEM', roomId);
        },
        updateOrderItems(context, items) {
            context.commit('UPDATE_ORDER_ITEMS', items);
        },
        updateOrderId(context, id) {
            context.commit('UPDATE_ORDER_ID', id);
        },
        updateOrderAmount(context, amount) {
            context.commit('UPDATE_ORDER_AMOUNT', amount);
        },
        updateCustomer(context, params) {
            context.commit('UPDATE_CUSTOMER', params.customer);
        },
        updateRooms(context, rooms) {
            context.commit('UPDATE_ROOMS', rooms);
        },
        updateOffer(context, offer) {
            context.commit('UPDATE_OFFER', offer);
        },
        showError(context, error) {
            context.commit('ERROR', error);
        },
        hideError(context) {
            context.commit('ERROR', null);
        },
        showLoader(context, loader) {
            context.commit('LOADER', loader);
        },
        hideLoader(context) {
            context.commit('LOADER', null);
        }
    }
});

export default store;