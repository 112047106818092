<template>
    <div class="app__block">
        <Header
            :title="'Администратор отеля'"
            :hideClose="true"
        />
        <div class="app__body">
            <div
                v-if="$store.state.loader"
                class="rooms__block"
            >
                <Loader />
            </div>
            <div 
                v-else
                class="rooms__block"
            >
                <div 
                    ref="type-1"
                    class="rooms__block-navigate"
                >
                    <a 
                        href="#" 
                        class="rooms__block-navigate-item is-active"
                        @click.prevent="scrollTo('type-1')"
                    >
                        Номера
                    </a>
                    <a 
                        href="#" 
                        class="rooms__block-navigate-item"
                        @click.prevent="scrollTo('type-2')"
                    >
                        Коттеджи
                    </a>
                </div>
                <RoomsRow
                    v-for="room in rooms.filter(item => item.type_place === 1)"
                    :key="`room-row-${room.id}`"
                    :item="room"
                    :dates="range"
                />
                <div 
                    ref="type-2"
                    class="rooms__block-navigate"
                >
                    <a 
                        href="#" 
                        class="rooms__block-navigate-item is-active"
                        @click.prevent="scrollTo('type-2')"
                    >
                        Коттеджи
                    </a>
                    <a 
                        href="#" 
                        class="rooms__block-navigate-item"
                        @click.prevent="scrollTo('type-1')"
                    >
                        Номера
                    </a>
                </div>
                <RoomsRow
                    v-for="room in rooms.filter(item => item.type_place === 2)"
                    :key="`room-row-${room.id}`"
                    :item="room"
                    :dates="range"
                />
                <div 
                    class="rooms__block-navigate"
                >
                    <a 
                        href="#" 
                        class="rooms__block-navigate-item"
                        @click.prevent="scrollTo('type-1')"
                    >
                        Номера
                    </a>
                    <a 
                        href="#" 
                        class="rooms__block-navigate-item"
                        @click.prevent="scrollTo('type-2')"
                    >
                        Коттеджи
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Loader from '@/components/loader.vue';
    import Header from '@/components/header';
    import RoomsRow from '@/components/rooms-row';
    export default {
        components: {
            Loader,
            Header,
            RoomsRow
        },
        data() {
            return {
                rooms: [],
                range: {
                    start: null, // || new Date(),
                    end: null, // || new Date(),
                },
            };
        },
        created() {
            this.$store.dispatch('hideError');
            this.getRoomsList();
            let dateStart = new Date();
            let dateEnd = new Date();
            dateEnd.setDate(dateEnd.getDate() + 1);
            this.range = {
                start: dateStart,
                end: dateEnd
            };
        },
        methods: {
            scrollTo(id) {
                console.log(id);
                this.$refs[id].scrollIntoView({ behavior: "smooth" });
            },
            getRoomsList() {
                this.$store.dispatch('showLoader', { label: 'Загрузка данных по номерам' });
                app.getRooms().then(res => {
                    let ids = [];
                    res.forEach(room => {
                        ids.push(room.id);
                    });
                    let params = {
                        id: ids,
                        date_from: new Date(this.range.start).toLocaleDateString('ru'),
                        date_to: new Date(this.range.end).toLocaleDateString('ru')
                    }
                    this.getRoomsTariffs(params);
                }).catch(err => {
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                    console.error(err);
                });
            },
            changeData(params) {
                console.log('Params', params);
                this.data = Object.assign(params, {
                    date_from: this.$store.state.dateStart || null,
                    date_to: this.$store.state.dateEnd || null,
                });


                if (this.data.id !== this.room.id) {
                    this.room = this.rooms.filter(item => item.id == params.id)[0] || {};
                }

                this.room.peoples = this.data.peoples;
                if (this.room.tariff && this.data.tariff !== this.room.tariff.id) {
                    if (!this.room.tariff) {
                        let tariff = this.room.tariff_list.filter(titem => { 
                            if (titem.base_tariff === true) { return titem; } 
                        })[0];
                        this.room.tariff = tariff;
                        //this.$store.dispatch('updateOrderItems', this.order.items);
                    } else {
                        let tariff = this.room.tariff_list.filter(titem => { 
                            if (titem.id === this.data.tariff) { return titem; } 
                        })[0];
                        if (!tariff) {
                            tariff = this.room.tariff_list.filter(titem => { 
                                if (titem.base_tariff === true) { return titem; } 
                            })[0];
                        }
                        this.room.tariff = tariff;
                        //this.tariffSelectedId = tariff.id;
                    }
                }
                this.getPrice();
            },
            getRoomsTariffs(params) {
                console.log('Get Rooms Tariffs', params);
                app.getTariffRoom(params).then(res => {
                    this.$store.dispatch('hideLoader');
                    console.log(res);
                    res.forEach(room => {
                        let idx = this.rooms.map(function(e) { return e.id; }).indexOf(res.id);
                        if (idx !== -1) {
                            this.rooms[idx] = room;
                        } else {
                            this.rooms.push(room);
                        }
                    });
                    this.rooms = res;

                    //this.getPrice();
                }).catch(err => {
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                    console.error(err);
                });
            },
            getPrice() {
                console.log('Get Price', this.room);
                if (this.room.id && this.room.tariff) {
                    this.price = this.$order.orderRoomPrice(this.room);
                }
            }
        }
    };
</script>
