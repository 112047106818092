<template>
    <div
        ref="topBlock"
        class="room"
    >
        <div
            v-bind:style="{ left: blockMore.arrowPosX }"
            class="room__arrow"
        />
        <div class="room__inner">
            <div class="room__block">
                <div class="room__gallery">
                    <div class="room__gallery-picture">
                        <div class="room__gallery-picture-inner">
                            <Splide
                                ref="gallery"
                                :options="galleryOptions"
                            >
                                <SplideSlide
                                    :key="`${room.id}-pic-0`"
                                >
                                    <a
                                        :data-src="room.picture"
                                        data-fancybox="gallery"
                                        data-preload="false"
                                    >
                                        <img
                                            :src="room.picture"
                                            alt=""
                                        >
                                    </a>
                                </SplideSlide>
                                <SplideSlide
                                    v-for="(photo, index) in room.photos"
                                    :key="`${room.id}-pic-${index + 1}`"
                                >
                                    <a
                                        :data-src="photo"
                                        data-fancybox="gallery"
                                        data-preload="false"
                                    >
                                        <img
                                            :src="photo"
                                            alt=""
                                        >
                                    </a>
                                </SplideSlide>
                            </Splide>
                        </div>
                    </div>
                    <div class="room__gallery-thumbs">
                        <Splide
                            ref="gallery_thumbs"
                            :options="galleryThumbsOptions"
                        >
                            <SplideSlide
                                :key="`${room.id}-thumb-0`"
                            >
                                <img
                                    :src="room.picture"
                                    alt=""
                                >
                            </SplideSlide>
                            <SplideSlide
                                v-for="(photo, index) in room.photos"
                                :key="`${room.id}-thumb-${index + 1}`"
                            >
                                <img
                                    :src="photo"
                                    alt=""
                                >
                            </SplideSlide>
                        </Splide>
                    </div>
                </div>
                <div class="room__info">
                    <div class="room__info-name">
                        {{ room.name }}
                    </div>
                    <div class="room__info-subname">
                        {{ room.sub_name }}
                    </div>
                    <div class="room__info-block">
                        <div class="room__info-params">
                            <div class="room__info-param m--area">
                                {{ room.area }} кв.м
                            </div>
                            <div class="room__info-param m--persons">
                                до {{ $helpers.stringForNumber((room.place_count + room.extra_place_count), ['человека', 'человек', 'человек']) }}
                            </div>
                        </div>
                        <div class="room__info-prices">
                            <div class="room__info-price m--new">
                                от <span>{{ $helpers.toPrice(room.price || 0) }}</span> <span class="m--currency">₽</span>
                            </div>
                            <!--div class="room__info-price m--old">от <span>5 200</span> <span class="m--currency">₽</span></div-->
                            <div class="room__info-included">
                                {{ room.included }}
                            </div>
                        </div>
                        <div class="room__info-buttons">
                            <button
                                v-if="button === 'order'"
                                class="button room__button m--blue"
                                :class="basket ? 'is-active' : ''"
                                :disabled="room.free_rooms.length === 0 ? 1 : 0"
                                @click.prevent="addToBasket(room.id)"
                            >
                                {{ basket ? 'Выбран' : 'Забронировать' }}
                            </button>
                            <template
                                v-else
                            >
                                <button
                                    v-if="!basket || room.free_rooms.length < 2"
                                    class="button room__button m--blue"
                                    :class="basket ? 'is-active' : ''"
                                    :disabled="room.free_rooms.length === 0 ? 1 : 0"
                                    @click.prevent="addToBasket(room.id)"
                                >
                                    {{ basket ? 'В корзине' : 'В корзину' }}
                                </button>
                                <div
                                    v-else
                                    class="button room__button m--blue-outline m--counter"
                                >
                                    <button
                                        class="button room__button-counter m--blue m--minus"
                                        @click.prevent="changeInBasket(room.id, -1)"
                                    >
                                        -
                                    </button>
                                    <div
                                        class="room__button-number"
                                    >
                                        {{ count }}
                                    </div>
                                    <button
                                        class="button room__button-counter m--blue m--plus"
                                        :disabled="room.free_rooms.length === count ? 1 : 0"
                                        @click.prevent="changeInBasket(room.id, 1)"
                                    >
                                        +
                                    </button>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div
                        class="room__info-description content"
                        v-html="room.content_base"
                    />
                    <div
                        v-if="showCalendar && room.free_rooms.length === 0"
                        class="room__info-calendar calendar"
                    >
                        <div
                            v-if="calendarLoader"
                            class="rooms__block"
                        >
                            <Loader
                                :propLoader="calendarLoader"
                            />
                        </div>
                        <template
                            v-else
                        >
                            <template
                                v-if="calendarRoomIdxs.length"
                            >
                                <div
                                    v-if="calendarRoomIdxs.length > 1"
                                    class="calendar__variants"
                                >
                                    <div class="calendar__variants-label">
                                        Варианты:
                                    </div>
                                    <div class="calendar__variants-buttons">
                                        <button
                                            v-for="(variant, index) in calendarRoomIdxs"
                                            :key="`variant-${index}`"
                                            class="calendar__variants-button button m--blue-outline"
                                            :class="index === calendarRoomIdx ? 'is-active' : ''"
                                            @click="changeVariant(index)"
                                        >
                                            {{ index + 1 }}
                                        </button>
                                    </div>
                                </div>
                                <DatePicker
                                    v-model="range"
                                    mode="date"
                                    :masks="masks"
                                    :available-dates="availableDates"
                                    :rows="$screens({ default: 1, sm: 1 })"
                                    :columns="$screens({ default: 1, sm: 2 })"
                                    :popover="{ placement: 'top' }"
                                    is-range
                                >
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <Splide
                                            ref="calendar"
                                            :options="calendarOptions"
                                        >
                                            <SplideSlide
                                                v-for="(date, index) in calendarRoom[calendarRoomIdxs[calendarRoomIdx]]"
                                                :key="`day-${index}`"
                                                class="calendar__item"
                                            >
                                                <div class="calendar__item-day">
                                                    {{ date.weekday }}<br>
                                                    {{ date.date.split('.')[0] }} {{ monthStr[date.date.split('.')[1] - 1] }}
                                                </div>
                                                <div
                                                    v-if="date.free"
                                                    class="calendar__item-price"
                                                    @click="togglePopover()"
                                                >
                                                    <span>{{ $helpers.toPrice(date.price) }}</span> <span class="m--currency">₽</span>
                                                </div>
                                                <div
                                                    v-else
                                                    class="calendar__item-price is-disabled"
                                                />
                                            </SplideSlide>
                                        </Splide>
                                        <div
                                            v-if="inputValue.start && inputValue.end"
                                            class="calendar__params"
                                        >
                                            <div class="calendar__params-dates">
                                                {{ inputValue.start }} - {{ inputValue.end }}
                                            </div>
                                            <button
                                                class="button m--blue calendar__params-button"
                                                @click="changeDate()"
                                            >
                                                Изменить данные
                                            </button>
                                        </div>
                                    </template>
                                </DatePicker>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <div class="room__params">
                <div
                    v-for="(block, iblock) in room.specifications"
                    :key="`block-${iblock}`"
                    class="room__params-block"
                >
                    <div class="room__params-title">
                        {{ block.name }}
                    </div>
                    <div
                        v-for="(param, iparam) in block.specificationsblocks"
                        :key="`param-${iblock}-${iparam}`"
                        :class="`m--${param.class_name}`"
                        class="room__param"
                    >
                        {{ param.value }}
                    </div>
                </div>
            </div>
            <div class="room__buttons">
                <a
                    href="#"
                    class="room__buttons-close"
                    @click.prevent="closeMoreInfo()"
                >
                    Свернуть описание
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { app } from '@/services';
    import Loader from '@/components/loader.vue';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { Fancybox } from "@fancyapps/ui";
    import { DatePicker } from 'v-calendar';

    export default {
        components: {
            Loader,
            DatePicker,
            Splide,
            SplideSlide,
        },
        props: {
            room: {
                type: Object,
                default() { return null; }
            },
            blockMore: {
                type: Object,
                default() { return {}; }
            },
            basket: {
                type: Boolean,
                default() { return false; }
            },
            count: {
                type: Number,
                default() { return 0; }
            },
            button: {
                type: String,
                default() { return null; }
            },
            showCalendar: {
                type: Boolean,
                default() { return false; }
            }
        },
        data: function() {
            return {
                masks: {
                    input: 'DD.MM.YYYY',
                },
                range: {
                    start: null,
                    end: null
                },
                availableDates: [],
                galleryOptions: {
                    type        : 'loop',
                    arrows      : false,
                    start       : 0,
                    //fixedHeight: '100%',
                    //cover: true
                },
                galleryThumbsOptions: {
                    type        : 'slide',
                    rewind      : true,
                    start       : 0,
                    //width       : 800,
                    gap         : '20px',
                    pagination  : false,
                    fixedWidth  : 160,
                    fixedHeight : 110,
                    cover       : true,
                    focus       : 'center',
                    isNavigation: true,
                    arrowPath: ' ',
                },
                calendarOptions: {
                    type        : 'slide',
                    rewind      : false,
                    start       : 0,
                    //width       : 800,
                    gap         : '0px',
                    pagination  : false,
                    fixedWidth  : 80,
                    //fixedHeight : 110,
                    //cover       : true,
                    focus       : 'center',
                    isNavigation: true,
                    arrowPath: ' ',
                    perMove: 7,
                },
                calendarRoom: {},
                calendarRoomIdx: 0,
                calendarRoomIdxs: [],
                calendarLoader: null,
                monthStr: [
                    'янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
                ],
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
            Fancybox.defaults.Hash = false;
            //Fancybox.defaults.helpers.overlay.locked = false;
            this.$refs.gallery.sync( this.$refs.gallery_thumbs.splide );
            this.$refs.topBlock.scrollIntoView({ behavior: "smooth" });
            if (this.showCalendar === true && this.room.free_rooms.length === 0) {
                let dateStart = new Date(this.$store.state.dateStart) || new Date();
                let dateEnd = new Date(this.$store.state.dateEnd) || new Date();
                dateStart.setDate(dateEnd.getDate() - 14);
                if (dateStart < new Date()) {
                    dateStart = new Date();
                }
                dateEnd.setDate(dateEnd.getDate() + 14);
                let data = this.$store.state.data;
                let params = {
                    date_from: dateStart.toLocaleDateString('ru'),
                    date_to: dateEnd.toLocaleDateString('ru'),
                    code_1c: this.room.code_1c,
                    people_count: data.people || 1,
                    teen_count: data.teen || 0,
                    children_count: data.children || 0
                };
                this.calendarLoader = { label: 'Загрузка занятости' };
                app.getCalendarRoom(params).then(res => {
                    this.calendarLoader = null;
                    this.calendarRoom = res.free_rooms_list
                    Object.keys(this.calendarRoom).forEach(key => {
                        this.calendarRoomIdxs.push(key);
                    });
                    this.calcRange(0);
                }).catch(err => {
                    this.calendarLoader = null;
                    this.$store.dispatch('showError', { err });
                    console.error(err);
                });
            }
        },
        methods: {
            changeVariant(index) {
                this.calendarRoomIdx = index;
                this.calcRange(index);
            },
            closeMoreInfo() {
                let params = {
                    id: null,
                }
                this.$emit('moreInfo', params);
            },
            addToBasket(id) {
                if (this.button === 'order') {
                    this.$emit('setToBasket', id);
                    this.$emit('next');
                } else {
                    this.$emit('addToBasket', id);
                }
            },
            changeInBasket(id, direction) {
                let params = {
                    id: id,
                    direction: direction
                }
                this.$emit('changeInBasket', params);
            },
            changeDate() {
                console.log('changeDate');
                let params = {
                    date_start: this.range.start,
                    date_end: this.range.end,
                    data: this.$store.state.data
                }
                this.$store.dispatch('updateData', params);
                this.$emit('getRooms', params);
            },
            calcRange(index) {
                if (!this.calendarRoomIdxs.length) {
                    return false;
                }
                this.availableDates = [];
                this.range = {
                    start: null,
                    end: null
                };
                let start = null;
                let end = null;
                let days = this.calendarRoom[this.calendarRoomIdxs[index]].length;
                this.calendarRoom[this.calendarRoomIdxs[index]].forEach((date, idx) => {
                    if (date.free) {
                        if (!start) {
                            start = this.$helpers.parseDate(date.date, 'DD.MM.YYYY');
                        } else {
                            end = this.$helpers.parseDate(date.date, 'DD.MM.YYYY');
                        }
                    }
                    if (start && ((end && !date.free) || (end && days === idx + 1))) {
                        this.availableDates.push({
                            start: start,
                            end: end
                        });
                        start = null;
                        end = null;
                    }
                });
            },
        }
    };
</script>
