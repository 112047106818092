<template>
    <div class="app__block">
        <Header
            :title="'Введите данные'"
        />
        <div class="app__body">
            <div class="form row">
                <DatePicker 
                    v-model="range"
                    mode="date"
                    :masks="masks"
                    :min-date="new Date()"
                    :rows="$screens({ default: 1, sm: 1 })"
                    :columns="$screens({ default: 1, sm: 2 })"
                    is-range
                >
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="form__row">
                            <div class="form__field field m--width-50">
                                <div class="field__label">
                                    Дата заезда
                                </div>
                                <div class="field__input">
                                    <input
                                        class="m--calendar"
                                        name="date_start"
                                        :value="inputValue.start"
                                        v-on="inputEvents.start"
                                    />
                                </div>
                            </div>
                            <div class="form__field field m--width-50">
                                <div class="field__label">
                                    Дата выезда
                                </div>
                                <div class="field__input">
                                    <input
                                        class="m--calendar"
                                        :class="dateError ? 'is-error' : ''"
                                        name="date_end"
                                        :value="inputValue.end"
                                        v-on="inputEvents.end"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </DatePicker>
                <RoomPersons
                    :data="data"
                    @changeData="changeData"
                />
            </div>
        </div>
        <div class="app__footer">
            <button 
                class="button m--blue"
                :disabled="range != null && (range.start != null && range.end != null && data.rooms != null && data.people != null) && !dateError ? 0 : 1"
                @click="next()"
            >
                Найти свободные номера
            </button>
        </div>
    </div>
</template>

<script>
    import { DatePicker } from 'v-calendar';
    import Header from '@/components/header';
    import RoomPersons from '@/components/room-persons';
    export default {
        components: {
            Header,
            DatePicker,
            RoomPersons,
        },
        data() {
            return {
                range: {
                    start: this.$store.state.dateStart || null, // || new Date(),
                    end:  this.$store.state.dateEnd || null, // || new Date(),
                },
                masks: {
                    input: 'DD.MM.YYYY',
                },
                data: this.$store.state.data,
                dateError: false
            };
        },
        watch: {
            range(value) {
                this.dateError = false;
                if (value.start.toLocaleDateString('ru') == value.end.toLocaleDateString('ru')) {
                    this.dateError = true;
                }
            }
        },
        created() {
            this.$store.dispatch('hideError');
        },
        methods: {
            changeData(params) {
                this.data = params.data;
                params = {
                    date_start: this.$store.state.dateStart,
                    date_end: this.$store.state.dateEnd,
                    data: this.data
                }
                this.$store.dispatch('updateData', params);
            },
            next() {
                let params = {
                    date_start: this.range.start,
                    date_end: this.range.end,
                    data: this.data
                }
                this.$store.dispatch('clearOrder');
                this.$store.dispatch('updateData', params);
                //this.$store.dispatch('updateOrderItems', []);
                this.$router.push({ name: 'rooms' });
            },
        }
    };
</script>
