<template>
    <div class="app__block m--fixed-footer">
        <Header
            :step="2"
            :steps="3"
            :title="'Корзина. Шаг 1'"
            :back="'Вернуться к выбору номеров'"
            @prev="prev"
        />
        <div class="app__body rooms">
            <template
                v-if="rooms.length"
                class="rooms__block"
            >
                <div
                    v-if="room"
                    class="rooms__block"
                >
                    <template
                        v-for="(room, index) in order.items"
                        :key="`room${index}-${room.id}`"
                    >
                        <div
                            v-if="order.items[index]"
                            class="rooms__block-inner"
                        >
                            <TariffsRoomsItem
                                :index="index"
                                :id="room.id"
                                :room="rooms.filter(item => { if (item.id == room.id) { return item; } })[0]"
                                :type="`basket`"
                                @deleteItem="deleteItem"
                                @calcRoomPrice="calcRoomPrice"
                            />
                        </div>
                    </template>
                </div>
            </template>
            <template
                v-else
            >
                <div
                    v-if="$store.state.loader"
                    class="rooms__block"
                >
                    <Loader />
                </div>
                <div
                    v-else
                    class="rooms__block"
                >
                    Ничего не найдено
                </div>
            </template>
        </div>
        <div class="app__footer basket">
            <BasketBlock
                :order="order"
                @next="next"
            />
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Loader from '@/components/loader.vue';
    import Header from '@/components/header';
    import BasketBlock from '@/components/basket-block';
    import TariffsRoomsItem from '@/components/tariffs-rooms-item';

    export default {
        components: {
            Loader,
            Header,
            BasketBlock,
            TariffsRoomsItem,
        },
        data() {
            return {
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru') || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru') || new Date(),
                    },
                    //rooms: this.$store.state.rooms,
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    amount: this.$store.state.amount,
                },
                room: null,
                rooms: [
                ],
                tariffs_specials: [
                    {
                        name: 'День рождения',
                        info: 'Отдых на природе не означает отсутствие сервиса. Наш отель предлагает отметить в кругу друзей День Рождения, юбилей. А может покупку автомобиля?',
                        image: 'assets/img/temp/offer1.jpg',
                        selected: null
                    }, {
                        name: 'SPA-выходные',
                        info: 'Отдых на природе не означает отсутствие сервиса. Наш отель предлагает отметить в кругу друзей День Рождения, юбилей. А может покупку автомобиля?',
                        image: 'assets/img/temp/offer2.jpg',
                        selected: null
                    }
                ],
                tariffSelectedId: null,
            };
        },
        created() {
            this.getTariffRoom(this.order.items);
        },
        destroyed() {
        },
        mounted() {
        },
        methods: {
            getTariffRoom(items) {
                let ids = items.map(item => {
                    return item.id;
                });
                if (!ids) {
                    throw new Error('Не определены ID номера/номеров');
                } else {
                    this.$store.dispatch('showLoader', { label: 'Загрузка данных по номерам' });
                    let params = {
                        id: ids,
                        date_from: this.order.range.start,
                        date_to: this.order.range.end,
                    };
                    app.getTariffRoom(params).then(res => {
                        this.$store.dispatch('hideLoader');
                        this.rooms = res;
                        this.room = this.rooms[0];
                        //this.calcAmount();
                        this.order.items.forEach(item => {
                            console.log(item);
                            let room = this.rooms.filter(room => {
                                if (item.id == room.id) { return room; }
                            })[0];
                            console.log(room);
                            if (!item.tariff) {
                                let tariff = room.tariff_list.filter(titem => {
                                    if (titem.base_tariff == true) { return titem; }
                                })[0];
                                item.tariff = tariff
                                this.$store.dispatch('updateOrderItems', this.order.items);
                            } else {
                                let tariff = room.tariff_list.filter(titem => {
                                    if (titem.id == item.tariff.id) { return item; }
                                })[0];
                                console.log(tariff);
                                this.tariffSelectedId = tariff.id;
                            }
                            this.calcRoomPrice(item);
                        });
                        this.calcAmount();
                    }).catch(err => {
                        this.$store.dispatch('hideLoader');
                        console.error(err);
                        this.$store.dispatch('showError', { err });
                    });
                }
            },
            deleteItem(index) {
                console.log('INDEX: ', index);
                let items = this.order.items;
                let item = items[index];
                let product = {
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    brand: 'Крутики',
                    category: item.type_place_name,
                    quantity: 1,
                    list: 'Результаты поиска',
                    position: item.position
                };
                window.dataLayer.push({
                    ecommerce: {
                        currencyCode: 'RUB',
                        remove: {
                            product: [ product ]
                        }
                    }
                });
                items.splice(index, 1);
                this.$store.dispatch('updateOrderItems', items);
                this.getTariffRoom(items);
            },
            calcRoomPrice(item) {
                item.price = this.$order.orderRoomPrice(item);
                this.calcAmount();
            },
            calcAmount() {
                let amount = this.$order.orderAmount(this.order);
                this.$store.dispatch('updateOrderAmount', amount);
                this.order.amount = amount;
            },
            prev() {
                this.$router.push({ name: 'rooms' });
            },
            next() {
                this.$router.push({ name: 'basket-tariffs' });
            },
        }
    };
</script>
