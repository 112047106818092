<template>
    <div>
        <div class="form__row">
            <div class="form__field field m--width-100">
                <div class="field__label">
                    Количество номеров / коттеджей
                </div>
                <div class="field__input">
                    <Multiselect
                        v-model="rooms.value"
                        :options="rooms.options"
                        name="rooms"
                        class="m--rooms"
                        placeholder="Количество номеров"
                        @select="select()"
                        @clear="clear('rooms')"
                    />
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="form__field field m--width-50">
                <div class="field__label">
                    Количество взрослых
                </div>
                <div class="field__input">
                    <Multiselect
                        v-model="people.value"
                        :options="people.options"
                        class="m--person"
                        placeholder="старше 12 лет"
                        @select="select()"
                        @clear="clear('people')"
                    />
                </div>
            </div>
            <div class="form__field field m--width-50">
                <div class="field__label">
                    Количество детей
                </div>
                <div class="field__input">
                    <Multiselect
                        v-model="children.value"
                        :options="children.options"
                        class="m--person"
                        placeholder="дети до 6 лет"
                        @select="select()"
                        @clear="clear('children')"
                    />
                </div>
                <div class="field__input">
                    <Multiselect
                        v-model="teen.value"
                        :options="teen.options"
                        class="m--person"
                        placeholder="дети от 7 до 12 лет"
                        @select="select()"
                        @clear="clear('teen')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    export default {
        components: {
            Multiselect,
        },
        props: {
            index: {
                type: Number,
                default() { return 1; }
            },
            data: {
                type: Object,
                default() { return {}; }
            }
        },
        data: function() {
            return {
                rooms: {
                    value: this.data.rooms,
                    options: [
                        {value: 1, label: '1 номер'},
                        {value: 2, label: '2 номера'},
                        {value: 3, label: '3 номера'},
                        {value: 4, label: '4 номера'},
                        {value: 5, label: '5 номеров'},
                    ]
                },
                people: {
                    value: this.data.people,
                    options: [
                        {value: 1, label: '1 взрослый'},
                        {value: 2, label: '2 взрослых'},
                        {value: 3, label: '3 взрослых'},
                        {value: 4, label: '4 взрослых'},
                        {value: 5, label: '5 взрослых'},
                        {value: 6, label: '6 взрослых'},
                        {value: 7, label: '7 взрослых'},
                        {value: 8, label: '8 взрослых'},
                        {value: 9, label: '9 взрослых'},
                        {value: 10, label: '10 взрослых'},
                        {value: 11, label: '11 взрослых'},
                        {value: 12, label: '12 взрослых'},
                        {value: 13, label: '13 взрослых'},
                        {value: 14, label: '14 взрослых'},
                        {value: 15, label: '15 взрослых'},
                    ]
                },
                children: {
                    value: this.data.children,
                    options: [
                        {value: 1, label: '1 ребенок до 6 лет'},
                        {value: 2, label: '2 ребенка до 6 лет'},
                        {value: 3, label: '3 ребенка до 6 лет'},
                        {value: 4, label: '4 ребенка до 6 лет'},
                        {value: 5, label: '5 детей до 6 лет'},
                    ]
                },
                teen: {
                    value: this.data.teen,
                    options: [
                        {value: 1, label: '1 ребенок от 7 до 12 лет'},
                        {value: 2, label: '2 ребенка от 7 до 12 лет'},
                        {value: 3, label: '3 ребенка от 7 до 12 лет'},
                        {value: 4, label: '4 ребенка от 7 до 12 лет'},
                        {value: 5, label: '5 детей от 7 до 12 лет'},
                    ]
                }
            };
        },
        computed: {
        },
        created() {
        },
        methods: {
            select() {
                let params = {
                    data: {
                        rooms: this.rooms.value,
                        people: this.people.value,
                        children: this.children.value,
                        teen: this.teen.value,
                    }
                }
                this.$emit('changeData', params);
            },
            clear(param) {
                this[param].value = null;
                this.select();
            }
        }
    };
</script>
