<template>
    <div class="basket__block">
        <div class="basket__block-params">
            <div class="basket__block-params-dates">
                {{ order.range.start }} - {{ order.range.end }}
            </div>
            <div
                class="basket__block-params-rooms"
            >
                {{ $helpers.stringForNumber(order.items.length || 0, ['номер', 'номера', 'номеров']) }}, {{ $helpers.stringForNumber(peoples, ['гость', 'гостя', 'гостей']) }}
            </div>
        </div>
        <div class="basket__block-control">
            <div class="basket__block-sum">
                <span>{{ $helpers.toPrice(order.amount || 0) }}</span> <span class="m--currency">₽</span>
            </div>
            <div class="basket__block-buttons">
                <button
                    class="button basket__block-button m--blue"
                    :disabled="order.items.length > 0 ? 0 : 1"
                    @click="$emit('next')"
                >
                    Продолжить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            order: {
                type: Object,
                default() { return null; }
            },
        },
        data: function() {
            return {
            };
        },
        computed: {
            peoples() {
                let guestsTotal = 0;
                this.order.items.forEach(item => {
                    guestsTotal = guestsTotal + item.peoples.people + item.peoples.teen + item.peoples.children;
                });
                return guestsTotal || (this.order.data.people + this.order.data.children + this.order.data.teen);
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
        }
    };
</script>
