<template>
    <div 
        class="tariffs__item"
        :class="selected ? 'is-selected' : ''"
    >
        <div class="tariffs__item-inner">
            <div class="tariffs__item-title">
                {{ tariff.name }}
            </div>
            <div class="tariffs__item-block">
                <div class="tariffs__item-block-param m--icon-clock">
                    {{ tariff.time }}
                </div>
                <div
                    v-for="(item, index) in tariff.included"
                    :key="index"
                    class="tariffs__item-block-param"
                    :class="'m--icon-' + item.key"
                >
                    {{ item.name }}
                </div>
                <div class="tariffs__item-block-param m--icon-food">{{ tariff.about }}</div>
            </div>
            <a 
                href="#" 
                class="tariffs__item-button button m--small"
                :class="selected ? 'm--blue-outline' : 'm--blue'"
                @click.prevent="select(id)"
            >
                {{selected ? 'Отменить' : 'Выбрать'}}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            id: {
                type: Number,
                default() { return null; }
            },
            index: {
                type: Number,
                default() { return null; }
            },
            tariff: {
                type: Object,
                default() { return null; }
            },
            selected: {
                type: Boolean,
                default() { return false; }
            },
        },
        data: function() {
            return {
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            select(id) {
                let params = {
                    index: this.index,
                    id: (this.selected) ? null : id,
                }
                this.$emit('tariffSelect', params);
            }
        }
    };
</script>
