<template>
    <div class="order__guests-item">
        <div class="form__row">
            <div class="form__row-title h3">
                Гость {{ this.index + 1 }}
                <a
                    href="#"
                    class="form__row-link"
                    @click.prevent="remove()"
                >
                    Удалить гостя
                </a>
            </div>
            <div class="form__field field m--width-33">
                <div class="field__label">Фамилия <span>*</span></div>
                <div class="field__input">
                    <input
                        v-model="guest.lname"
                        :class="guestFieldsErrors.lname ? 'is-error' : ''"
                        name="lname"
                        placeholder="Введите данные"
                    />
                </div>
            </div>
            <div class="form__field field m--width-33">
                <div class="field__label">Имя <span>*</span></div>
                <div class="field__input">
                    <input
                        v-model="guest.fname"
                        :class="guestFieldsErrors.fname ? 'is-error' : ''"
                        name="fname"
                        placeholder="Введите данные"
                    />
                </div>
            </div>
            <div class="form__field field m--width-33">
                <div class="field__label">Отчество <span>*</span></div>
                <div class="field__input">
                    <input
                        v-model="guest.sname"
                        :class="guestFieldsErrors.sname ? 'is-error' : ''"
                        name="sname"
                        placeholder="Введите данные"
                    />
                </div>
            </div>
        </div>
        <div class="form__row">
            <div class="form__field field m--width-33">
                <div class="field__label">Дата рождения <span>*</span></div>
                <div class="field__input">
                    <DatePicker
                        v-model="guest.birthday"
                        mode="date"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                class="m--calendar"
                                :class="guestFieldsErrors.birthday ? 'is-error' : ''"
                                name="birthday"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </template>
                    </DatePicker>
                </div>
            </div>
            <div class="form__field field m--width-33">
                <div class="field__label">Серия паспорта</div>
                <div class="field__input">
                    <input
                        v-model="guest.pasport_series"
                        class=""
                        name="pasport_series"
                        placeholder="Введите данные"
                    />
                </div>
            </div>
            <div class="form__field field m--width-33">
                <div class="field__label">Номер паспорта</div>
                <div class="field__input">
                    <input
                        v-model="guest.pasport_number"
                        class=""
                        name="pasport_number"
                        placeholder="Введите данные"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DatePicker } from 'v-calendar';
    export default {
        components: {
            DatePicker,
        },
        props: {
            index: {
                type: Number,
                default() { return 1; }
            },
            guestData: {
                type: Object,
                default() { return {}; }
            },
            guestFieldsErrors: {
                type: Object,
                default() { return {}; }
            }
        },
        data: function() {
            return {
                birthday: null, //new Date()
                guest: this.guestData
            };
        },
        computed: {
        },
        created() {
        },
        methods: {
            remove() {
                let params = {
                    index: this.index,
                }
                this.$emit('deleteGuestData', params);
            }
        }
    };
</script>
