<template>
    <div class="order__rooms">
        <div class="order__rooms-header">
            <div class="order__rooms-position">№</div>
            <div class="order__rooms-name">Ваш номер</div>
            <div class="order__rooms-params m--food">Питание</div>
            <!--div class="order__rooms-params m--actions">Акции</div-->
            <div class="order__rooms-params m--advanced">Дополнительные услуги</div>
        </div>
        <div
            v-for="(room, index) in rooms"
            class="order__rooms-row"
        >
            <div class="order__rooms-position">{{ index + 1 }}</div>
            <div class="order__rooms-name">{{ room.name }}</div>
            <div class="order__rooms-params m--food">{{ room.tariff.name }}</div>
            <!--div class="order__rooms-params m--actions">День рождения</div-->
            <div class="order__rooms-params m--advanced">
                <div
                    v-for="option in room.options"
                    class="order__rooms-param"
                >
                    <div class="order__rooms-param-name">
                        {{ option.name }}
                    </div>
                    <div class="order__rooms-param-value">
                        {{ option.count }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            rooms: {
                type: Object,
                default() { return null; }
            },
        },
        data: function() {
            return {
            };
        },
        computed: {
        },
        created() {
        },
        methods: {
        }
    };
</script>
