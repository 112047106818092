<template>
    <div 
        ref="topPage"
        class="app__block"
    >
        <Header
            :step="4"
            :steps="3"
            :title="'Результаты бронирования'"
        />
        <div 
            v-if="$store.state.loader"
            class="app__body order"
        >
            <Loader />
        </div>
        <div
            v-else
            class="app__body order m--center"
        >
            <div 
                v-if="orderId && orderStatus === 1"
                class="order__result"
            >
                <div class="order__result-item h2">
                    Вы внесли оплату в сумме <span>{{ $helpers.toPrice(order.amount || 0) }} ₽</span>
                </div>
                <div class="order__result-item h2">
                    Ваша бронь <span>№ {{ order.id }}</span>. Бронирование подтверждаем.
                </div>
                <div class="order__result-desc">
                    По всем вопросам, касающимся Вашего бронирования, пожалуйста, обращайтесь по телефону <a href="tel:+73517009075">+7(351) 700-90-75</a> или по электронной почте <a href="mailto:krutiki.rezort@yandex.ru">krutiki.rezort@yandex.ru</a>
                </div>
            </div>
            <div
                v-else
                class="order__result is-alert"
            >
                <div class="order__result-item h2">
                    Что-то пошло не так!
                </div>
                <div 
                    v-if="order.id"
                    class="order__result-item h2"
                >
                    Ваша бронь <span>№ {{ order.id }}</span>.
                </div>
                <div
                    v-else
                    class="order__result-item h2"
                >
                    <span>Не определен номер Вашей брони.</span>
                </div>
                <div class="order__result-desc">
                    По всем вопросам, касающимся Вашего бронирования, пожалуйста, обращайтесь по телефону <a href="tel:+73517009075">+7(351) 700-90-75</a> или по электронной почте <a href="mailto:krutiki.rezort@yandex.ru">krutiki.rezort@yandex.ru</a>
                </div>
            </div>
        </div>
        <div class="app__footer" />
    </div>
</template>

<script>
    import { app } from "@/services";
    import Loader from '@/components/loader.vue';
    import Header from '@/components/header';
    export default {
        components: {
            Loader,
            Header,
        },
        props: {
        },
        data() {
            return {
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru') || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru') || new Date(),
                    },
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    id: this.$store.state.id,
                    amount: this.$store.state.amount,
                },
                orderId: null,
                orderStatus: null
            };
        },
        created() {
            let query = this.$router.currentRoute.value.query;
            if (query.orderId) {
                this.orderId = query.orderId;
                this.statusPayment();
            }
        },
        destroyed() {
        },
        mounted() {
            this.$refs.topPage.scrollIntoView();
        },
        methods: {
            statusPayment() {
                let params = {
                    order_id: this.order.id,
                    order_bank: this.orderId
                };
                this.$store.dispatch('showLoader', { label: 'Проверка статуса оплаты' });
                app.statusPaymentMir(params).then(res => {
                    console.log(res);
                    if (res.error) {
                        this.$store.dispatch('hideLoader');
                        this.$store.dispatch('showError', {err: { message: '[ ' + res.error + ' ] ' + res.detail }});
                    } else {
                        this.changeOrderState(res.orderStatus);
                    }
                }).catch(err => {
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                    console.error(err);
                });
            },
            changeOrderState(orderStatus) {
                let params = {
                    order_id: this.order.id,
                    order_status: 1,
                    bank: 'rostour_mir'
                };
                app.changeOrderState(params).then(res => {
                    this.$store.dispatch('hideLoader');
                    console.log(res);
                    if (res.order_status !== '1') {
                        this.$store.dispatch('showError', {err: { message: 'Не удалось финализировать бронь' }});
                        this.orderStatus = 0;
                    } else {
                        this.orderStatus = 1;
                    }
                }).catch(err => {
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                    console.error(err);
                });
            }
        }
    };
</script>
