<template>
    <div 
        v-if="order.items[index]"
        class="room__persons-item"
        :class="readOnly ? 'm--read-only' : ''"
    >
        <div class="room__persons-item-name">
            {{ persons[name].label }}
        </div>
        <div class="room__persons-item-count">
            <button
                v-if="!readOnly" 
                class="button m--blue-outline room__persons-item-button m--minus"
                :disabled="(order.items[index].peoples[name] <= persons[name].minValue) ? 1 : 0"
                @click.prevent="peoplesChange(name, -1)"
            >
                -
            </button>
            <div class="room__persons-item-nums">
                {{ order.items[index].peoples[name] }}
            </div>
            <button 
                v-if="!readOnly" 
                class="button m--blue room__persons-item-button m--plus"
                :disabled="disabledIncPeoples(order.items[index].peoples, maxCount)"
                @click.prevent="peoplesChange(name, 1)"
            >
                +
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            index: {
                type: Number,
                default() { return 0; }
            },
            maxCount: {
                type: Number,
                default() { return 0; }
            },
            name: {
                type: String,
                default() { return null; }
            },
            readOnly: {
                type: Boolean,
                default() { return false; }
            },
        },
        data: function() {
            return {
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru'),// || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru'),// || new Date(),
                    },
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    amount: this.$store.state.amount,
                },
                persons: {
                    people: {
                        label: 'Количество взрослых',
                        minValue: 1
                    },
                    children: {
                        label: 'Количество детей до 6',
                        minValue: 0
                    },
                    teen: {
                        label: 'Количество детей от 7 до 12',
                        minValue: 0
                    }
                }
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            peoplesChange(id, direction) {
                //console.log('Peoples change', id, direction);
                let item = this.order.items[this.index];
                let peoples = item.peoples;
                let count = peoples[id] || 0;
                count = count + direction;
                if (count < 0) { count = 0; }
                peoples[id] = count;
                this.$store.dispatch('updateOrderItems', this.order.items);
                this.$emit('calcRoomPrice', item);
            },
            disabledDecPeoples(peoples, min) {
                return (peoples.people + peoples.teen + peoples.children > min) ? 0 : 1;
            },
            disabledIncPeoples(peoples, max) {
                return (peoples.people + peoples.teen + peoples.children < max) ? 0 : 1;
            },
        }
    };
</script>
