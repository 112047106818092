<template>
    <div
        ref="topPage" 
        class="app__block m--fixed-footer"
    >
        <Header
            :step="2"
            :steps="3"
            :title="'Выберите тариф'"
            :back="'Вернуться к выбору номеров'"
            @prev="prev"
        />
        <div class="app__body rooms">
            <template 
                v-if="rooms.length"
            >
                <div 
                    v-if="room"
                    class="rooms__block"
                >
                    <div
                        v-for="(room, index) in rooms"
                        :key="room.id"
                        class="rooms__block-inner"
                    >
                        <TariffsRoomsItem 
                            :index="index"
                            :room="room"
                            @getTariffRoom="getTariffRoom"
                            @calcRoomPrice="calcRoomPrice"
                        />
                    </div>

                    <div class="room__tariffs tariffs">
                        <template
                            v-if="room.tariff_list"
                        >
                            <h2 class="tariffs__title h2">Тарифы</h2>
                            <div
                                class="tariffs__block"
                            >
                                <TariffsFoodItem
                                    v-for="tariff in room.tariff_list.filter(item => { if (item.base_tariff == false) { return item; } })"
                                    :key="tariff.id"
                                    :id="tariff.id"
                                    :index=0
                                    :tariff="tariff"
                                    :selected="(tariffSelectedId == tariff.id) ? true : false"
                                    @tariffSelect="tariffSelect"
                                />
                            </div>
                        </template>
                        <h2 class="tariffs__title h2">Дополнительные услуги</h2>
                        <div 
                            v-if="room.options"
                            class="tariffs__advanced"
                        >
                            <TariffsAdvancedItem
                                v-for="item in room.options"
                                :key="item.id"
                                :index=0
                                :tariff="item"
                                :option="order.items[0].options.filter(option => { if ( option.id == item.id) { return option; } })[0] || {}"
                                @tariffAdvancedChange="tariffAdvancedChange"
                            />
                        </div>
                        <div
                            v-if="offersLoader"
                            class="tariffs__specials specials"
                        >
                            <Loader 
                                :propLoader="offersLoader"
                            />
                        </div>
                        <template
                            v-else
                        >
                            <div 
                                v-if="tariffs_specials.length"
                                class="tariffs__specials specials"
                            >
                                <h2 class="specials__title h2">Специальные предложения</h2>
                                <div class="specials__block">
                                    <TariffsSpecialsItem
                                        v-for="(item, index) in tariffs_specials"
                                        :key="index"
                                        :tariff="item"
                                        :selected="item.id === offerSelectedId ? true : false"
                                        :disabled="disabledSpecialItem(item)"
                                        @tariffSpecialsCheck="tariffSpecialsCheck"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                    <div 
                        v-if="comfortRoomId"
                        class="room__comfort comfort"
                    >
                        <h2 class="comfort__title h2">Повысить комфорт</h2>
                        <div
                            v-if="comfort"
                            class="rooms__block-inner"
                        >
                            <ComfortRoomsItem 
                                :index=0
                                :room="comfort"
                                @upgradeItem="upgradeItem"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template
                v-else
            >
                <div
                    v-if="$store.state.loader"
                    class="rooms__block"
                >
                    <Loader />
                </div>

                <div 
                    v-else
                    class="rooms__block"
                >
                    Ничего не найдено
                </div>
            </template>
        </div>
        <div class="app__footer basket">
            <BasketBlock
                :order="order"
                @next="next"
            />
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Loader from '@/components/loader.vue';
    import Header from '@/components/header';
    import BasketBlock from '@/components/basket-block';
    import TariffsRoomsItem from '@/components/tariffs-rooms-item';
    import TariffsFoodItem from '@/components/tariffs-food-item';
    import TariffsAdvancedItem from '@/components/tariffs-advanced-item';
    import TariffsSpecialsItem from '@/components/tariffs-specials-item';
    import ComfortRoomsItem from '@/components/comfort-rooms-item';

    export default {
        components: {
            Loader,
            Header,
            BasketBlock,
            TariffsRoomsItem,
            TariffsFoodItem,
            TariffsAdvancedItem,
            TariffsSpecialsItem,
            ComfortRoomsItem
        },
        data() {
            return {
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru') || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru') || new Date(),
                    },
                    //rooms: this.$store.state.rooms,
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    offer: this.$store.state.offer,
                    amount: this.$store.state.amount,
                },
                room: null,
                rooms: [
                ],
                tariffSelectedId: null,
                tariffs_specials: [],
                offersLoader: null,
                offerSelectedId: null,
                comfortRoomId: null,
                comfort: null,
                deltaDate: null
            };
        },
        created() {
            this.getTariffRoom(this.order.items);
            this.offerSelectedId = (this.$store.state.offer) ? this.$store.state.offer.id : null;
            this.deltaDate = Math.abs(this.$helpers.parseDate(this.order.range.end, 'DD.MM.YYYY') - this.$helpers.parseDate(this.order.range.start, 'DD.MM.YYYY'));
            this.deltaDate = Math.ceil(this.deltaDate / (1000 * 60 * 60 * 24));
        },
        destroyed() {
        },
        mounted() {
        },
        methods: {
            disabledSpecialItem(item) {
                if (item.min_days && item.min_days > this.deltaDate) {
                    return true;
                }
                if (item.date_from && item.date_to) {
                    if (this.$helpers.parseDate(this.order.range.start, 'DD.MM.YYYY') >= this.$helpers.parseDate(item.date_from, 'YYYY-MM-DD') && this.$helpers.parseDate(this.order.range.end, 'DD.MM.YYYY') <= this.$helpers.parseDate(item.date_to, 'YYYY-MM-DD')) {
                        return false;
                    }
                    return true;
                }
            },
            getTariffRoom(items) {
                let ids = items.map(item => { return item.id; });
                if (!ids) {
                    throw new Error('Не определены ID номера/номеров');
                } else {
                    this.$store.dispatch('showLoader', { label: 'Загрузка данных по номерам' });
                    let params = {
                        id: ids,
                        date_from: this.order.range.start,
                        date_to: this.order.range.end,
                    };
                    app.getTariffRoom(params).then(res => {
                        this.$store.dispatch('hideLoader');
                        this.rooms = res;
                        this.room = this.rooms[0];
                        this.order.items.forEach(item => {
                            console.log(item);
                            let room = this.rooms.filter(room => {
                                if (item.id === room.id) { return room; }
                            })[0];
                            console.log(room);
                            if (!item.tariff) {
                                let tariff = room.tariff_list.filter(titem => { 
                                    if (titem.base_tariff === true) { return titem; } 
                                })[0];
                                item.tariff = tariff;
                                this.$store.dispatch('updateOrderItems', this.order.items);
                            } else {
                                let tariff = room.tariff_list.filter(titem => { 
                                    if (titem.id === item.tariff.id) { return item; } 
                                })[0];
                                if (!tariff) {
                                    tariff = room.tariff_list.filter(titem => { 
                                        if (titem.base_tariff === true) { return titem; } 
                                    })[0];
                                }
                                this.tariffSelectedId = tariff.id;
                            }
                            this.calcRoomPrice(item);
                        });
                        this.calcAmount();
                        //Берем данные для повышения комфорта
                        let rooms = this.$store.state.rooms;
                        let cRoom = rooms.filter(item => { 
                            if (item.id == this.room.id) { return item; } 
                        })[0];
                        let nRoom = rooms.filter(item => { 
                            if (item.id == cRoom.next_spaces) { return item; } 
                        })[0];
                        //console.log('FREE ROOMS:', nRoom.free_rooms.length, nRoom.free_rooms);
                        if (nRoom && nRoom.free_rooms.length) {
                            this.comfortRoomId = cRoom.next_spaces || null;
                        } else {
                            this.comfortRoomId = null;
                        }
                        if (this.comfortRoomId) {
                            let params = {
                                id: [this.comfortRoomId],
                                date_from: this.order.range.start,
                                date_to: this.order.range.end,
                            };
                            app.getTariffRoom(params).then(res => {
                                this.$store.dispatch('hideLoader');
                                this.comfort = res[0];
                                this.comfort.free_rooms = nRoom.free_rooms;
                                this.comfort.peoples = this.order.items[0].peoples;
                                if (!this.order.items[0].tariff) {
                                    let tariff = this.comfort.tariff_list.filter(titem => { 
                                        if (titem.base_tariff === true) { return titem; } 
                                    })[0];
                                    this.comfort.tariff = tariff;
                                } else {
                                    let tariff = this.comfort.tariff_list.filter(titem => { 
                                        if (titem.id == this.order.items[0].tariff.id) { return titem; } 
                                    })[0];
                                    if (!tariff) {
                                        tariff = this.comfort.tariff_list.filter(titem => { 
                                            if (titem.base_tariff === true) { return titem; } 
                                        })[0];
                                    }
                                    this.comfort.tariff = tariff;
                                }
                                this.calcRoomPrice(this.comfort);
                            }).catch(err => {
                                this.$store.dispatch('hideLoader');
                                console.error(err);
                                this.$store.dispatch('showError', { err });
                            });
                        }
                    }).catch(err => {
                        this.$store.dispatch('hideLoader');
                        console.error(err);
                        this.$store.dispatch('showError', { err });
                    });
                    this.offersLoader = { label: 'Загрузка спец.предложений' };
                    app.getOffers().then(res => {
                        this.offersLoader = null;
                        this.tariffs_specials = res;
                    }).catch(err => {
                        this.offersLoader = null;
                        console.error(err);
                        this.$store.dispatch('showError', { err });
                    });
                }
            },
            tariffSelect(params) {
                console.log('Index: ', params.index);
                let item = this.order.items[params.index];
                this.tariffSelectedId = params.id;
                console.log(this.tariffSelectedId);
                item.tariff = 
                    this.room.tariff_list.filter(item => { if (item.id === params.id) { return item; } })[0] ||
                    this.room.tariff_list.filter(item => { if (item.base_tariff === true) { return item; } })[0];
                this.$store.dispatch('updateOrderItems', this.order.items);
                if (this.comfort) {
                    this.comfort.tariff = 
                        this.comfort.tariff_list.filter(item => { if (item.id === params.id) { return item; } })[0] ||
                        this.comfort.tariff_list.filter(item => { if (item.base_tariff === true) { return item; } })[0];
                }
                this.calcRoomPrice(item);
            },
            tariffAdvancedChange(params, id) {
                console.log('Index: ', params.index);
                let item = this.order.items[params.index];
                let options = item.options;
                let option = options.filter(option => { if ( option.id === params.id) { return option; } })[0];
                if (!option) {
                    option = this.room.options.filter(option => { if ( option.id === params.id) { return option; } })[0];
                }
                let count = option.count || 0;
                count = count + params.direction;
                if (count < 0) { count = 0; }
                //this.tariffs_advanced[params.id].count = count;
                option.count = count;
                console.log(params);
                console.log(option);

                let idx = options.map(function(e) { return e.id }).indexOf(params.id);
                if (idx == -1) {
                    options.push(option);
                } else {
                    if (option.count > 0) {
                        options[idx] = option;
                    } else {
                        options.splice(idx, 1);
                    }
                }
                this.$store.dispatch('updateOrderItems', this.order.items);
                this.calcAmount();
            },
            tariffSpecialsCheck(params) {
                if (this.offerSelectedId === params.id) {
                    this.offerSelectedId = null;
                    this.$store.dispatch('updateOffer', null);
                    this.order.offer = null;
                } else {
                    this.offerSelectedId = params.id;
                    let offer = this.tariffs_specials.filter(item => { if ( item.id === params.id) { return item; } })[0];
                    this.$store.dispatch('updateOffer', offer);
                    this.order.offer = offer;
                }
                this.calcAmount();
            },
            calcRoomPrice(item) {
                item.price = this.$order.orderRoomPrice(item);
                if (this.comfort) {
                    this.comfort.price = this.$order.orderRoomPrice(this.comfort);
                }
                this.calcAmount();
            },
            calcAmount() {
                let amount = this.$order.orderAmount(this.order);
                this.$store.dispatch('updateOrderAmount', amount);
                this.order.amount = amount;
            },
            upgradeItem(index) {
                delete this.comfort.options;
                this.order.items[index] = Object.assign(this.order.items[index], this.comfort);
                this.$store.dispatch('updateOrderItems', this.order.items);
                this.$refs.topPage.scrollIntoView({ behavior: 'smooth' });
                this.getTariffRoom(this.order.items);
            },
            prev() {
                this.$router.push({ name: 'rooms' });
            },
            next() {
                this.$router.push({ name: 'order' });
            },
        }
    };
</script>
