<template>
    <div
        ref="topPage"
        class="app__block m--fixed-footer"
    >
        <Header
            :step="3"
            :steps="3"
            :title="'Данные гостей'"
            :back="order.items.length > 1 ? 'Вернуться в корзину' : 'Вернуться к выбору тарифов'"
            @prev="prev"
        />
        <div class="app__body order">
            <template
                v-if="alertError"
            >
                <div class="order__alert">
                    {{ alertError }}
                </div>
            </template>
            <template
                v-else
            >
                <div class="order__block">
                    <div class="order__block-inner">
                        <div class="order__params">
                            <div class="order__params-dates">
                                {{ order.range.start }} - {{ order.range.end }}
                            </div>
                            <div class="order__params-rooms">
                                {{ $helpers.stringForNumber(order.data.rooms, ['номер', 'номера', 'номеров']) }}, {{ $helpers.stringForNumber(guestsTotal || 0, ['гость', 'гостя', 'гостей']) }}
                            </div>
                        </div>
                        <OrderRooms
                            :rooms="order.items"
                        />
                        <div
                            v-if="showOffer && order.offer"
                            class="order__specials"
                        >
                            Специальные предложения: {{ order.offer.name }}
                        </div>
                        <div
                            v-if="$store.state.loader"
                            class="order__total h1"
                        >
                            <Loader />
                        </div>
                        <div
                            v-else
                            class="order__total h1"
                        >
                            Итог: <span>{{ $helpers.toPrice(order.amount || 0) }} ₽</span>
                        </div>
                        <div class="order__devider" />
                        <div class="order__info">
                            <div class="order__info-tabs">
                                <div class="order__info-tabs-menu">
                                    <a
                                        v-if="showTabs[0]"
                                        ref="itemTab0"
                                        href="#"
                                        class="order__info-tabs-item"
                                        :class="(openedTabId == 0) ? 'is-active' : ''"
                                        @click.prevent="showTab({ index: 0, refItem: $refs.itemTab0 })"
                                    >
                                        Детали бронирования
                                    </a>
                                    <a
                                        v-if="showTabs[1]"
                                        ref="itemTab1"
                                        href="#"
                                        class="order__info-tabs-item"
                                        :class="(openedTabId == 1) ? 'is-active' : ''"
                                        @click.prevent="showTab({ index: 1, refItem: $refs.itemTab1 })"
                                    >
                                        Правила проживания
                                    </a>
                                    <a
                                        v-if="showTabs[2]"
                                        ref="itemTab2"
                                        href="#"
                                        class="order__info-tabs-item"
                                        :class="(openedTabId == 2) ? 'is-active' : ''"
                                        @click.prevent="showTab({ index: 2, refItem: $refs.itemTab2 })"
                                    >
                                        Правила отмены бронирования
                                    </a>
                                </div>
                                <div
                                    ref="tabBlock"
                                    class="order__info-tabs-blocks"
                                >
                                    <div
                                        v-if="showTabs[0]"
                                        class="order__info-tabs-block"
                                        :class="(openedTabId == 0) ? 'is-opened' : ''"
                                    >
                                        <div
                                            class="order__info-tabs-arrow"
                                            :style="{ left: blockTab.arrowPosX }"
                                        />
                                        <a
                                            href="#"
                                            class="order__info-tabs-close"
                                            @click.prevent="hideTab()"
                                        />
                                        <div class="order__info-content content">
                                            <div class="h1">
                                                Правила проживания в курортном отеле «Крутики Тургояк»
                                            </div>
                                            <ol>
                                                <li>Номерной фонд отеля и коттеджи предназначены для временного проживания. Режим работы стойки ресепшн с 09.00 до 23.00.&nbsp; Внутренний телефон администратора: 101, единый корпоративный: +7 (351) 240-82-90</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div
                                        v-if="showTabs[1]"
                                        class="order__info-tabs-block"
                                        :class="(openedTabId == 1) ? 'is-opened' : ''"
                                    >
                                        <div
                                            class="order__info-tabs-arrow"
                                            :style="{ left: blockTab.arrowPosX }"
                                        />
                                        <a
                                            href="#"
                                            class="order__info-tabs-close"
                                            @click.prevent="hideTab()"
                                        />
                                        <div class="order__info-content content">
                                            <div class="h1">
                                                Правила проживания в курортном отеле «Крутики Тургояк»
                                            </div>
                                            <ol>
                                                <li>Номерной фонд отеля и коттеджи предназначены для временного проживания. Режим работы стойки ресепшн с 09.00 до 23.00.&nbsp; Внутренний телефон администратора: 101, единый корпоративный: +7 (351) 240-82-90</li>
                                                <li>При заселении в отеле необходимо предоставить администратору паспорт заявителя резерва для регистрации и выдачи электронного ключа от номера или коттеджа.</li>
                                                <li>Оплата за проживание производится на ресепшн по счету, наличным расчетом, либо по терминалу. В отеле действует зона WI-FI (пароль у администратора).</li>
                                                <li>Забронировать и оплатить проживание возможно через сайт отеля, на ресепшн и по телефону с выставлением счета на оплату.</li>
                                                <li>Заселение в номера в 16.00, выезд в 13.00. Коттеджи №1 - №8 заезд в 18.00, выезд -15.00. Коттеджи №10 - №14 заезд в 15.00, выезд в 12.00. Продление проживания рассчитывается по дополнительному прайс-листу.</li>
                                                <li>Администратор знакомит гостей с правилами проживания в отеле, правилами пожарной безопасности (подпись гостя в журнале ПБ) и предлагаемыми дополнительными услугами.</li>
                                                <li>Смена белья производится один раз в три дня, смена полотенец производится ежедневно (по просьбе гостей).</li>
                                                <li>Гости проживающих могут находиться в отеле с 09.00 до 23.00 часов с предварительной регистрацией у администратора и оплатой - 500,00 рублей/гость.</li>
                                                <li>В целях безопасности и сохранности имущества гости проживающих не должны находиться в отеле без присутствия самих проживающих.</li>
                                                <li>Запрещается распивать спиртные напитки, курить в помещениях отеля, шуметь и мешать отдыху других проживающих в отеле.</li>
                                                <li>В случае потери или порчи имущества, гость возмещает стоимость нанесенного ущерба в соответствии с установленным прейскурантом.</li>
                                                <li>Войдя в номер/коттедж, гостю необходимо вставить электронный ключ в устройство энергосбережения для подключения электричества.</li>
                                                <li>Уходя из номера, гостю необходимо убрать электронный ключ из устройства энергосбережения (220 Вт), закрыть окна и краны в ванной комнате.&nbsp;</li>
                                                <li>Размещение в номерном фонде отеля с животными строго запрещено, в коттеджах - только с животными массой не более 5 кг. Стоимость специальной уборки номера &nbsp;2000 руб. (оплачивается при заселении).</li>
                                                <li>Курение во всех помещениях отеля строго запрещено, только в отведенном месте на улице. Оплата за специальную уборку номера – 5000,00 рублей.</li>
                                                <li>Во время регистрации с домашним животным, гость обязан внести&nbsp; дополнительную оплату за кошку или собаку: 2000,00 рублей один раз за все время проживания.</li>
                                                <li>За сохранность вещей, не помещенных в сейф, администрация ответственности не несет.</li>
                                                <li>Все возникшие замечания и пожелания гость может оставить в виде записи в книге жалоб и предложений на ресепшн.</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div
                                        v-if="showTabs[2]"
                                        class="order__info-tabs-block"
                                        :class="(openedTabId == 2) ? 'is-opened' : ''"
                                    >
                                        <div
                                            class="order__info-tabs-arrow"
                                            :style="{ left: blockTab.arrowPosX }"
                                        />
                                        <a
                                            href="#"
                                            class="order__info-tabs-close"
                                            @click.prevent="hideTab()"
                                        />
                                        <div class="order__info-content content">
                                            <div class="h1">
                                                Порядок аннуляции заказа в курортном отеле «Крутики Тургояк»
                                            </div>
                                            <p>Совершая бронирование на сайте Отеля, Вы принимаете и соглашаетесь с порядком аннуляции заказа. Пожалуйста, обратите внимание, что определѐнные тарифы и специальные предложения не подлежат аннуляции или изменению. Мы рекомендуем Вам внимательно ознакомиться с описанием конкретного номера и особыми правилами до завершения процесса бронирования.<br> Для аннуляции бронирования и возврата уплаченного задатка предусмотрен следующий порядок.</p>
                                            <p>Заявитель должен представить письменное заявление с указанием номера брони, суммы и банковских реквизитов счета, на который будет возвращен задаток. Образец может быть выслан гостю по его просьбе на электронный адрес.</p>
                                            <p>Заявление будет рассмотрено в течение 10 рабочих дней.</p>
                                            <p>Денежные средства возвращаются за вычетом фактически понесенных отелем расходов, а именно, комиссии банков и других расходов отеля, если они фактически подтверждены. В соответствии со статьей 782 ГК РФ и статьей 32 Закона № 2300-1, пункта 32 «Правил предоставления гостиничных услуг в Российской Федерации" от 09.10.2015 года № 1085.</p>
                                            <p>Расходы отеля на возврат задатка:</p>
                                            <p>Если оплата была произведена через расчетный счет (от юр.лиц и ип), возврат на расчетный счет - 60 рублей.</p>
                                            <p>Если оплата была произведена через терминал по карте на стойке ресепшн, возврат на карту физического лица - 4 % от суммы к возврату.</p>
                                            <p>Если оплатили через сайт картой физлица, возврат на карту физлица - 5 % от суммы к возврату.</p>
                                            <p>При оплате наличными на стойке ресепшн, а возврат на карту физлица - 5 %.</p>
                                            <p>Если оплатили по счету, а возврат на карту физлица - 5 %.</p>
                                            <p><strong>Аннуляция брони на проживание на период с 25 декабря по 10 января и с 20 июня по 10 августа по любой причине:</strong></p>
                                            <ul>
                                                <li>За 30 дней до даты заезда - возврат предоплаты в полном объеме;</li>
                                                <li>В период от 30 до 7 дней до даты заезда - взимается плата за бронирование в размере 50% от суммы по счету;</li>
                                                <li>Менее, чем за 7 дней до даты заезда - взимается плата за бронирование в размере 100% от суммы по счету.</li>
                                            </ul>
                                            <p><strong>Аннуляция брони на проживание на период с 11 января по 19 июня и с 11 августа по 24 декабря по любой причине:</strong></p>
                                            <ul>
                                                <li>Более чем за 24 часа - возврат предоплаты в полном объеме.</li>
                                                <li>Менее чем за 24 часа до даты заезда - взимается плата за бронирование в размере 30% от суммы по счету.</li>
                                            </ul>
                                            <p><strong>Аннуляция брони на аренду залов по любой причине в период с 01 июня по 30 сентября, с 15 декабря по 15 января по любой причине:</strong></p>
                                            <ul>
                                                <li>Более чем за 30 дней до даты мероприятия - возврат в полном объеме.</li>
                                                <li>Менее чем за 30 дней - взимается плата за бронирование зала в размере 100% от суммы предоплаты.</li>
                                            </ul>
                                            <p><strong>Аннуляция брони на аренду залов на период с 16 января по 30 апреля и 01 октября по 14 декабря по любой причине:</strong></p>
                                            <ul>
                                                <li>Отказ за 15 дней до даты мероприятия - возврат в полном объеме.</li>
                                                <li>Отказ менее чем за 15 дней- взимается плата за бронирование зала в размере 100% от суммы предоплаты.</li>
                                            </ul>
                                            <p>Оплата по счету или с сайта отеля означает согласие с условиями бронирования и возврата денежных средств.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="order__block">
                    <div class="order__contacts">
                        <div class="order__block-title h2">
                            Контактные данные
                        </div>
                        <div class="order__block-description content">
                            <p>После оплаты брони на электронную почту вам придёт подтверждение бронирования. При необходимости мы свяжемся с вами по телефону, чтобы уточнить детали.</p>
                        </div>
                        <div
                            ref="customerForm"
                            class="order__contacts-form form"
                        >
                            <div class="form__row">
                                <div
                                    class="form__field field m--width-33"
                                >
                                    <div class="field__label">
                                        Фамилия <span>*</span>
                                    </div>
                                    <div class="field__input">
                                        <input
                                            v-model="customer.lname"
                                            :class="customerFieldsErrors.lname ? 'is-error' : ''"
                                            name="lname"
                                            placeholder="Введите данные"
                                        >
                                    </div>
                                </div>
                                <div
                                    class="form__field field m--width-33"
                                >
                                    <div class="field__label">
                                        Имя <span>*</span>
                                    </div>
                                    <div class="field__input">
                                        <input
                                            v-model="customer.fname"
                                            :class="customerFieldsErrors.fname ? 'is-error' : ''"
                                            name="fname"
                                            placeholder="Введите данные"
                                        >
                                    </div>
                                </div>
                                <div
                                    class="form__field field m--width-33"
                                >
                                    <div class="field__label">
                                        Отчество <span>*</span>
                                    </div>
                                    <div class="field__input">
                                        <input
                                            v-model="customer.sname"
                                            :class="customerFieldsErrors.sname ? 'is-error' : ''"
                                            name="sname"
                                            placeholder="Введите данные"
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="form__row">
                                <div
                                    class="form__field field m--width-33"
                                >
                                    <div class="field__label">
                                        Электронная почта <span>*</span>
                                    </div>
                                    <div class="field__input">
                                        <input
                                            v-model="customer.email"
                                            :class="customerFieldsErrors.email ? 'is-error' : ''"
                                            name="email"
                                            placeholder="Введите данные"
                                        >
                                    </div>
                                </div>
                                <div
                                    class="form__field field m--width-33"
                                >
                                    <div class="field__label">
                                        Телефон <span>*</span>
                                    </div>
                                    <div class="field__input">
                                        <input
                                            v-maska="'+# (###) ###-####'"
                                            v-model="customer.phone"
                                            :class="customerFieldsErrors.phone ? 'is-error' : ''"
                                            name="phone"
                                            placeholder="+X (XXX) XXX-XXXX"
                                        >
                                    </div>
                                </div>
                                <div
                                    class="form__field field m--width-33"
                                >
                                    <div class="field__label">
                                        Дата рождения <span>*</span>
                                    </div>
                                    <div class="field__input">
                                        <DatePicker
                                            v-model="customer.birthday"
                                            mode="date"
                                        >
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <input
                                                    class="m--calendar"
                                                    :class="customerFieldsErrors.birthday ? 'is-error' : ''"
                                                    name="birthday"
                                                    :value="inputValue"
                                                    v-on="inputEvents"
                                                >
                                            </template>
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                            <div class="form__row">
                                <div class="form__field field m--width-33 m--no-margin">
                                    <div class="field__label">
                                        Промокод
                                    </div>
                                    <div class="field__input">
                                        <input
                                            v-model="customer.promocode"
                                            :class="customerFieldsErrors.promocode || alertPromoError ? 'is-error' : ''"
                                            name="promocode"
                                            :placeholder="alertPromoError ? alertPromoError : 'Введите данные'"
                                            :disabled="order.offer ? true : false"
                                        >
                                    </div>
                                </div>
                                <div class="form__field order__promo field m--width-66 m--no-margin">
                                    <button
                                        class="order__promo-button button m--blue-outline m--small"
                                        :disabled="disablePromo ? 1 : 0"
                                        @click.prevent="submitPromo()"
                                    >
                                        <svg
                                            v-if="loaderPromo"
                                            class="spinner"
                                            viewBox="0 0 50 50"
                                        >
                                            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                                        </svg>
                                        Применить
                                    </button>
                                </div>
                                <div
                                    v-if="order.promocode"
                                    class="order__alert"
                                >
                                    Ваша скидка по промокоду: {{ order.promocode.percent }}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order__devider" />
                    <template v-if="showGuestsBlock">
                        <div class="order__guests">
                            <div class="order__block-title h2">
                                Информация о гостях
                            </div>
                            <div class="order__block-description content">
                                <p>Если вы планируете отдых с гостями, Вы можете сообщить нам об этом заранее. Добавляя гостя сейчас, экономится время при заселении.</p>
                            </div>
                            <div class="order__guests-form form">
                                <div
                                    v-for="(guest, index) in guests"
                                    :key="`guest-${index}`"
                                    :ref="`guestForm${index}`"
                                >
                                    <OrderGuestsItem
                                        :index="index"
                                        :guestData="guest"
                                        :guestFieldsErrors="guestFieldsErrors[index]"
                                        @changeGuestData="changeGuestData"
                                        @deleteGuestData="deleteGuestData"
                                    />
                                </div>
                                <div
                                    v-if="guests.length < guestsTotal"
                                    class="form__row-add"
                                >
                                    Добавить данные гостя
                                    <button
                                        class="button m--blue m--small m--icon m--icon-plus-white m--circle"
                                        @click="addGuest()"
                                    >
                                        <span />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="order__devider" />
                    </template>
                    <div class="order__times">
                        <div class="order__block-title h2">
                            Время заезда и выезда
                        </div>
                        <div class="order__block-description content">
                            <p>Уважаемые гости, просим Вас обращать внимание на время заезда и выезда.</p>
                        </div>
                        <div class="order__times-table">
                            <div class="order__times-header">
                                <div class="order__times-position">№</div>
                                <div class="order__times-name">Ваш номер</div>
                                <div class="order__times-param m--start">Время заезда</div>
                                <div class="order__times-param m--stop">Время выезда</div>
                            </div>
                            <div
                                v-for="(room, index) in order.items"
                                class="order__times-row"
                            >
                                <div class="order__times-position">{{ index + 1 }}</div>
                                <div class="order__times-name">{{ room.name }}</div>
                                <div class="order__times-param m--start">{{ room.time_start.replace(/(:\d{2})$/, '') || '14:00' }}</div>
                                <div class="order__times-param m--stop">{{ room.time_stop.replace(/(:\d{2})$/, '') || '12:00' }}</div>
                            </div>
                        </div>
                        <div class="order__block-description content">
                            <div class="order__block-description-title">Время работы Spa-центра</div>
                            <p>
                                Понедельник с 10.00 до 12.00 и с 17.00 до 22.00.<br>
                                Вторник - воскресенье с 10.00 до 15.00 и с 17.00 до 22.00.<br>
                                В перерывах проводится генеральная уборка.
                            </p>
                        </div>
                        <div
                            v-if="showTimes"
                            class="order__times-form form"
                        >
                            <div class="form__row">
                                <div class="form__field field m--width-33">
                                    <div class="field__label">
                                        Время заезда
                                    </div>
                                    <div class="field__input">
                                        <Multiselect
                                            v-model="timeIn.value"
                                            :options="timeIn.options"
                                            name="time_in"
                                            placeholder=""
                                            @select="select()"
                                        />
                                    </div>
                                </div>
                                <div class="form__field field m--width-33">
                                    <div class="field__label">
                                        Время выезда
                                    </div>
                                    <div class="field__input">
                                        <Multiselect
                                            v-model="timeOut.value"
                                            :options="timeOut.options"
                                            name="time_out"
                                            placeholder=""
                                            @select="select()"
                                        />
                                    </div>
                                </div>
                                <div class="form__field field m--width-33" />
                            </div>
                        </div>
                    </div>
                    <div class="order__devider" />
                    <button
                        class="order__button button m--blue m--small"
                        :disabled="disableSubmit ? 1 : 0"
                        @click.prevent="submitOrder()"
                    >
                        <svg
                            v-if="loaderSubmit"
                            class="spinner"
                            viewBox="0 0 50 50"
                        >
                            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                        </svg>
                        Оплатить
                    </button>
                    <!--div class="order__advanced">
                        <div class="order__block-title h2">
                            Дополнительно
                        </div>
                        <div class="order__block-description content">
                            <p>Если у вас есть дополнительные пожелания, то пожалуйста напишите их в специальном окне ниже. Мы постараемся учесть ваши пожелания.</p>
                        </div>
                        <div class="order__advanced-form form">
                            <div class="form__field field m--width-100">
                                <div class="field__input">
                                    <textarea
                                        class=""
                                        name="advanced"
                                        placeholder="Введите данные"
                                    />
                                </div>
                            </div>
                        </div>
                    </div-->
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Multiselect from '@vueform/multiselect'
    import { DatePicker } from 'v-calendar';
    import Loader from '@/components/loader.vue';
    import Header from '@/components/header';
    import OrderRooms from '@/components/order-rooms';
    import OrderGuestsItem from '@/components/order-guests-item';
    export default {
        components: {
            Multiselect,
            DatePicker,
            Loader,
            Header,
            OrderRooms,
            OrderGuestsItem
        },
        data() {
            return {
                showTabs: [false, true, true],
                showGuestsBlock: false,
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru'),// || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru'),// || new Date(),
                    },
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    offer: this.$store.state.offer,
                    amount: this.$store.state.amount,
                },
                checkedAmount: null,
                openedTabId: null,
                blockTab: {
                    arrowPosX: null
                },
                showTimes: false,
                timeIn: {
                    value: null,
                    options: []
                },
                timeOut: {
                    value: null,
                    options: []
                },
                customer: this.$store.state.customer || {},
                customerFieldsErrors: {
                    fname: 0,
                    sname: 0,
                    lname: 0,
                    email: 0,
                    phone: 0,
                    birthday: 0
                },
                guests: this.$store.state.guests || [],
                guestFieldsErrors: [],
                guestsTotal: null,
                loaderPromo: false,
                disablePromo: false,
                loaderSubmit: false,
                disableSubmit: false,
                showOffer: true,
                alertError: null,
                alertPromoError: null
            };
        },
        created() {
            let guestsTotal = 0;
            this.order.items.forEach(item => {
                guestsTotal = guestsTotal + item.peoples.people + item.peoples.teen + item.peoples.children;
            });
            this.guestsTotal = guestsTotal;
            this.guests.forEach(item => {
                this.guestFieldsErrors.push(Object.assign({}, this.templateFieldsErrors()));
            });
            let params = this.prepareOrder();
            this.checkAmount(params);
            if (this.order.offer) {
                this.customer.promocode = this.order.offer.promo_code;
            }
        },
        destroyed() {
        },
        mounted() {
            this.$refs.topPage.scrollIntoView();
            let amount = this.$order.orderAmount(this.order);
            this.$store.dispatch('updateOrderAmount', amount);
        },
        methods: {
            templateFieldsErrors() {
                return {
                    fname: 0,
                    sname: 0,
                    lname: 0,
                    birthday: 0
                }
            },
            showTab(params) {
                this.openedTabId = (this.openedTabId != params.index) ? params.index : null
                this.blockTab.ref = params.refItem;
                console.log(this.blockTab.ref.offsetLeft);
                this.onResize();
            },
            hideTab() {
                this.openedTabId = null;
            },
            onResize() {
                const ref = this.blockTab.ref;
                this.blockTab.arrowPosX = ref.offsetLeft - 23 + 'px';
            },
            addGuest() {
                console.log('addGuest');
                this.$store.dispatch('addGuest');
                this.guestFieldsErrors.push(this.templateFieldsErrors());
            },
            changeGuestData(params) {
                this.guests[params.index] = params.data;
                this.$store.dispatch('updateGuests', { guests: this.guests });
            },
            deleteGuestData(params) {
                let guests = this.$store.state.guests;
                guests.splice(params.index, 1);
                this.guestFieldsErrors.splice(params.index, 1);
                this.$store.dispatch('updateGuests', { guests: this.guests });
            },
            submitPromo() {
                this.alertPromoError = null;
                this.customerFieldsErrors.promocode = (!this.customer.promocode) ? 1 : 0;
                if (!this.customerFieldsErrors.promocode) {
                    this.disablePromo = true;
                    this.loaderPromo = true;
                    let code = this.customer.promocode;
                    app.getPromo(code).then(res => {
                        this.disablePromo = false;
                        this.loaderPromo = false;
                        console.log(res);
                        this.order.promocode = res.detail;
                        let amount = this.$order.orderAmount(this.order);
                        this.$store.dispatch('updateOrderAmount', amount);
                        this.order.amount = amount;
                    }).catch(err => {
                        console.error(err.response.status);
                        this.disablePromo = false;
                        this.loaderPromo = false;
                        //this.$store.dispatch('hideLoader');
                        if (err.response.status === 400 && err.response.data) {
                            this.customer.promocode = null;
                            this.alertPromoError = err.response.data.detail;
                            delete this.order.promocode;
                            let amount = this.$order.orderAmount(this.order);
                            this.$store.dispatch('updateOrderAmount', amount);
                            this.order.amount = amount;
                        } else {
                            this.$store.dispatch('showError', { err });
                        }
                        console.error(err);
                    });
                }
            },
            submitOrder(type) {
                console.log('submitOrder', this.customer);
                let refError = null;
                Object.keys(this.customerFieldsErrors).forEach(key => {
                    this.customerFieldsErrors[key] = (!this.customer[key]) ? 1 : 0;
                    if (key === 'email') {
                        this.customerFieldsErrors[key] = (!this.$helpers.validateEmail(this.customer[key])) ? 1 : 0;
                    }
                    if (key === 'phone' && this.customer[key] && this.customer[key].length !== 17) {
                        this.customerFieldsErrors[key] = 1;
                    }
                    if (!refError && this.customerFieldsErrors[key] == 1) {
                        refError = this.$refs.customerForm;
                    }
                });
                this.guestFieldsErrors.forEach((item, index) => {
                    Object.keys(item).forEach(key => {
                        this.guestFieldsErrors[index][key] = (!this.guests[index][key]) ? 1 : 0;
                        if (!refError && this.guestFieldsErrors[index][key] == 1) {
                            refError = this.$refs['guestForm' + index];
                        }
                    });
                });
                this.$store.dispatch('updateCustomer', { customer: this.customer });
                this.$store.dispatch('updateGuests', { guests: this.guests });
                if (refError) {
                    refError.scrollIntoView({ behavior: "smooth" });
                } else {
                    let order = Object.assign(this.prepareOrder(), {
                        last_name: this.customer.lname,
                        first_name: this.customer.fname,
                        patronymic: this.customer.sname,
                        birth_day: new Date(this.customer.birthday).toLocaleDateString('ru'),
                        phone: this.customer.phone,
                        email: this.customer.email,
                        amount: this.order.amount,
                        //promo_code: this.customer.promocode
                    });
                    let guests = [];
                    this.guests.forEach(guest => {
                        guests.push({
                            last_name: guest.lname,
                            first_name: guest.fname,
                            patronymic: guest.sname,
                            birth_day: new Date(guest.birthday).toLocaleDateString('ru'),
                            series: guest.pasport_series,
                            number: guest.pasport_number
                        });
                    });
                    order.rooms[0].guests = guests;
                    console.log('OrderData', order);
                    this.disableSubmit = true;
                    this.loaderSubmit = true;
                    app.submitOrder(order).then(res => {
                        this.disableSubmit = false;
                        this.loaderSubmit = false;
                        //this.$store.dispatch('hideLoader');
                        console.log(res);
                        if (!res.order_id) {
                            this.$store.dispatch('showError', {err: { message: 'Не удалось создать бронь' }});
                        } else {
                            this.$store.dispatch('updateOrderId', res.order_id);
                            //this.$store.dispatch('updateOrderAmount', res.amount);
                            let products = this.order.items.map(item => {
                                return {
                                    id: item.id,
                                    name: item.name,
                                    price: item.price,
                                    brand: 'Крутики',
                                    category: item.type_place_name,
                                    quantity: 1,
                                    list: 'Результаты поиска',
                                    position: item.position
                                };
                            });
                            window.dataLayer.push({
                                ecommerce: {
                                    currencyCode: 'RUB',
                                    purchase: {
                                        actionField: {
                                            id: res.order_id
                                        },
                                        products: products
                                    }
                                }
                            });
                            this.next();
                        }
                    }).catch(err => {
                        console.error(err.response.status);
                        this.disableSubmit = false;
                        this.loaderSubmit = false;
                        //this.$store.dispatch('hideLoader');
                        if (err.response.status === 400 && err.response.data && err.response.data.payment) {
                            this.alertError = err.response.data.detail;
                        } else {
                            this.$store.dispatch('showError', { err });
                        }
                        console.error(err);
                    });
                }
            },
            prepareOrder() {
                let params = {
                    promo_code: this.customer.promocode
                }
                if (this.order.offer) {
                    params.promo_code = this.order.offer.promo_code;
                }
                let rooms = [];
                let free_rooms = {};
                this.order.items.forEach(item => {
                    free_rooms[item.id] = item.free_rooms.slice(0);
                });
                this.order.items.forEach(item => {
                    let options = [];
                    item.options.forEach(option => {
                        options.push({
                            option_1c_code: option.code_1c,
                            option_count: option.count,
                            amount: option.count * option.value
                        });
                    });
                    let free_rooms_1c_number = free_rooms[item.id][0];
                    free_rooms[item.id].splice(0, 1);
                    rooms.push({
                        date_from: this.order.range.start,
                        date_to: this.order.range.end,
                        code_1c: item.code_1c,
                        free_rooms_1c_number: free_rooms_1c_number,
                        tariff_1c_name: item.tariff.food_name_1c,
                        people_count: item.peoples.people,
                        teen_count: item.peoples.teen,
                        children_count: item.peoples.children,
                        amount: item.price,
                        options: options
                    });
                });
                params.rooms = rooms;
                console.log('PrepareOrder', params);
                return params;
            },
            checkAmount(params) {
                this.$store.dispatch('showLoader', { label: 'Калькуляция заказа' });
                app.getAmount(params).then(res => {
                    this.$store.dispatch('hideLoader');
                    console.log(res);
                    //if (this.order.amount != res.amount) {
                        this.checkedAmount = res.amount;
                    //}
                }).catch(err => {
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                    console.error(err);
                });
            },
            prev() {
                if (this.order.items.length > 1) {
                    this.$router.push({ name: 'basket' });
                } else {
                    this.$router.push({ name: 'tariffs' });
                }
            },
            next() {
                this.$router.push({ name: 'payment' });
            },
        }
    };
</script>
