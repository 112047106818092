import { createApp } from 'vue'

import router from './router/router';
import store from './store/store';
import helpers from './utils/helpers';
import order from './utils/order';
import { vfmPlugin } from 'vue-final-modal';
import { SetupCalendar } from 'v-calendar';
import VueYandexMetrika from 'vue3-yandex-metrika';
import Maska from 'maska';

import App from './App.vue'

const app = createApp(App);
app.use(store);
app.use(router);
app.use(helpers);
app.use(order);
app.use(vfmPlugin);
app.use(SetupCalendar, {});
app.use(Maska);

console.warn('YandexMetrika', process.env.NODE_ENV);
app.use(VueYandexMetrika, {
    id: 65740360,
    // id: 89112661,
    router: router,
    env: process.env.NODE_ENV,
    debug: false, // false,
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        ecommerce: 'dataLayer'
    }
});
window.dataLayer = window.dataLayer || [];
app.mount('#app');
