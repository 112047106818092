<template>
    <div class="rooms__block">
        <h2 class="rooms__block-title h2">
            {{ placeHeader }}
        </h2>
        <div class="rooms__block-inner">
            <div
                v-if="roomsData.length"
                ref="roomBlock"
                class="rooms__list"
            >
                <template
                    v-for="(room, index) in roomsData"
                    :key="index"
                >
                    <RoomsItem
                        v-if="room.type_place === placeType"
                        :id="index"
                        :room="room"
                        :opened="(openedMoreId === index) ? true : false"
                        :basket="($store.state.items.map(function(e) { return e.id }).indexOf(room.id) !== - 1) ? true : false"
                        :count="$store.state.items.filter(item => item.id === room.id).length"
                        :button="order.data.rooms > 1 ? 'add' : 'order'"
                        @moreInfo="moreInfo"
                        @addToBasket="addToBasket"
                        @setToBasket="setToBasket"
                        @changeInBasket="changeInBasket"
                        @next="next"
                    />
                    <template v-if="openedMoreId !== null && indexMoreBlock === index">
                        <RoomsItemInfo
                            :key="`more-${openedMoreId}`"
                            :room="roomsData[openedMoreId]"
                            :basket="($store.state.items.map(function(e) { return e.id }).indexOf(roomsData[openedMoreId].id) !== - 1) ? true : false"
                            :count="$store.state.items.filter(item => item.id === roomsData[openedMoreId].id).length"
                            :button="order.data.rooms > 1 ? 'add' : 'order'"
                            :blockMore="blockMore"
                            :showCalendar="order.data.rooms > 1 ? false : true"
                            @getRooms="getRooms"
                            @moreInfo="moreInfo"
                            @addToBasket="addToBasket"
                            @setToBasket="setToBasket"
                            @changeInBasket="changeInBasket"
                            @next="next"
                        />
                    </template>
                </template>
            </div>
            <div
                v-else
                ref="roomBlock"
                class="rooms__list"
            >
                <div class="rooms__alert">
                    Нет подходящих номеров.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RoomsItem from '@/components/rooms-item';
    import RoomsItemInfo from '@/components/rooms-item-info';

    export default {
        components: {
            RoomsItem,
            RoomsItemInfo,
        },
        props: {
            placeHeader: {
                type: String,
                default() { return null; }
            },
            placeType: {
                type: [Number, String],
                default() { return null; }
            },
            rooms: {
                type: Array,
                default() { return null; }
            }
        },
        data() {
            return {
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru') || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru') || new Date(),
                    },
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                },
                roomsData: this.rooms.filter(room => { if (room.type_place === this.placeType) { return room; } }),
                blockWidth: 0,
                blockHeight: 0,
                itemWidth: 0,
                itemsPerRow: 0,
                indexMoreBlock: 0,
                openedMoreId: null,
                blockMore: {
                    arrowPosX: null
                },
            };
        },
        created() {
        },
        destroyed() {
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        unmounted() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            moreInfo(params) {
                this.openedMoreId = params.id;
                this.blockMore.ref = params.refItem;
                this.onResize();
                if (params.id) {
                    window.dataLayer.push({
                        ecommerce: {
                            currencyCode: 'RUB',
                            detail: {
                                products: [
                                    {
                                        id: this.roomsData[params.id].id,
                                        name: this.roomsData[params.id].name,
                                        price: this.roomsData[params.id].price,
                                        brand: 'Крутики',
                                        category: this.roomsData[params.id].type_place_name,
                                        list: 'Результаты поиска',
                                        position: this.roomsData[params.id].position
                                    }
                                ]
                            }
                        }
                    });
                }
            },
            getRooms(params) {
                console.log('getRooms');
                this.$emit('getRooms', params);
            },
            addToBasket(id) {
                let items = this.$store.state.items;
                let idx = items.map(function(e) { return e.id }).indexOf(id);
                let item = this.rooms.filter(room => { if (room.id === id) { return room; } })[0];
                let product = {
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    brand: 'Крутики',
                    category: item.type_place_name,
                    quantity: 1,
                    list: 'Результаты поиска',
                    position: item.position
                };
                if (idx !== -1) {
                    window.dataLayer.push({
                        ecommerce: {
                            currencyCode: 'RUB',
                            remove: {
                                product: [ product ]
                            }
                        }
                    });
                    items.splice(idx, 1);
                    this.$store.dispatch('updateOrderItems', items);
                } else {
                    window.dataLayer.push({
                        ecommerce: {
                            currencyCode: 'RUB',
                            add: {
                                product: [ product ]
                            }
                        }
                    });
                    this.$store.dispatch('addOrderItem', {
                        id: item.id,
                        code_1c: item.code_1c * 1,
                        name: item.name,
                        count: 1,
                        price: item.price,
                        free_rooms: item.free_rooms,
                        time_start: item.time_start,
                        time_stop: item.time_stop,
                        tariff: null,
                        peoples: {
                            people: 1,
                            teen: 0,
                            children: 0
                        },
                        options: []
                    });
                }
                this.$emit('calcAmount');
            },
            setToBasket(id) {
                let item = this.rooms.filter(room => { if (room.id === id) { return room; } })[0];
                let product = {
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    brand: 'Крутики',
                    category: item.type_place_name,
                    quantity: 1,
                    list: 'Результаты поиска',
                    position: item.position
                };
                window.dataLayer.push({
                    ecommerce: {
                        currencyCode: 'RUB',
                        add: {
                            product: [ product ]
                        }
                    }
                });
                let items = [{
                    id: item.id,
                    code_1c: item.code_1c * 1,
                    name: item.name,
                    count: 1,
                    price: item.price,
                    free_rooms: item.free_rooms,
                    time_start: item.time_start,
                    time_stop: item.time_stop,
                    tariff: null,
                    peoples: {
                        people: this.order.data.people || 0,
                        teen: this.order.data.teen || 0,
                        children: this.order.data.children || 0
                    },
                    options: []
                }];
                this.$store.dispatch('updateOrderItems', items);
                this.$emit('calcAmount');
            },
            changeInBasket(params) {
                let id = params.id;
                let direction = params.direction;
                let items = this.$store.state.items;
                let item = this.rooms.filter(room => { if (room.id === id) { return room; } })[0];
                let product = {
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    brand: 'Крутики',
                    category: item.type_place_name,
                    quantity: 1,
                    list: 'Результаты поиска',
                    position: item.position
                };
                let count = items.filter(item => item.id === id).length;
                if (params.direction == 1) {
                    window.dataLayer.push({
                        ecommerce: {
                            currencyCode: 'RUB',
                            add: {
                                product: [ product ]
                            }
                        }
                    });
                    this.$store.dispatch('addOrderItem', {
                        id: item.id,
                        code_1c: item.code_1c * 1,
                        name: item.name,
                        count: 1,
                        price: item.price,
                        free_rooms: item.free_rooms,
                        time_start: item.time_start,
                        time_stop: item.time_stop,
                        tariff: null,
                        peoples: {
                            people: 1,
                            teen: 0,
                            children: 0
                        },
                        options: []
                    });
                } else {
                    let idx = items.map(function(e) { return e.id }).indexOf(id);
                    window.dataLayer.push({
                        ecommerce: {
                            currencyCode: 'RUB',
                            remove: {
                                product: [ product ]
                            }
                        }
                    });
                    items.splice(idx, 1);
                    this.$store.dispatch('updateOrderItems', items);
                }
                this.$emit('calcAmount');
            },
            onResize() {
                const roomBlock = this.$refs.roomBlock;
                const { clientWidth, clientHeight } = roomBlock;
                const ref = this.blockMore.ref;
                if (ref) {
                    this.blockMore.arrowPosX = ref.offsetLeft - 12 + ref.clientWidth / 2 + 'px';
                    this.blockWidth = clientWidth;
                    this.blockHeight = clientHeight;
                    this.itemWidth = ref.clientWidth + 20;
                    this.itemsPerRow = parseInt(this.blockWidth / this.itemWidth);
                    this.indexMoreBlock = Math.ceil((this.openedMoreId + 1) / this.itemsPerRow) * this.itemsPerRow - 1;
                    if (this.indexMoreBlock >= this.roomsData.length) {
                        this.indexMoreBlock = this.roomsData.length - 1;
                    }
                }
            },
            next() {
                this.$emit('next');
            },
        }
    };
</script>
