<template>
    <div 
        class="specials__item"
        :class="[selected ? 'is-selected' : '', disabled ? 'is-disabled' : '']"
    >
        <a 
            href="#" 
            class="specials__item-check"
            @click.prevent="select(tariff.id)"
        >
        </a>
        <div class="specials__item-inner">
            <div class="specials__item-bg">
                <img 
                    :src="tariff.picture" 
                    alt=""
                />
            </div>
            <div class="specials__item-title">
                {{ tariff.name }}
            </div>
            <div
                v-html="tariff.content" 
                class="specials__item-block"
            />
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            id: {
                type: Number,
                default() { return null; }
            },
            tariff: {
                type: Object,
                default() { return {}; }
            },
            disabled: {
                type: Boolean,
                default() { return false; }
            },
            selected: {
                type: Boolean,
                default() { return false; }
            }
        },
        data: function() {
            return {
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            select(id) {
                if (!this.disabled) {
                    this.$emit('tariffSpecialsCheck', { id: id });
                }
            }
        }
    };
</script>
