const ajax = {
    timeout: 45000,
    responseType: 'json',
    responseEncoding: 'utf8'
};

const serviceUrl = {
    url: '//mb1.krutiki.ru',
    localPath: '//mb1.krutiki.ru',
    port: '80',
    api: '/api',
    onLocal: false
}

let urlPath = `${serviceUrl.url}${serviceUrl.api}`;
if (serviceUrl.onLocal || window.location.hostname === 'localhost') {
    urlPath = `${serviceUrl.localPath}:${serviceUrl.port}${serviceUrl.api}`;
}

const app = {
    url: `${urlPath}`,
    token: '76a8f24b971842c21628733e0f0a4068725efba7'
};

const pdf = `${serviceUrl.url}/api/banking/pdf/`;

const cache = {
    storage: 'sessionStorage'
};

const logger = {
    url: `${urlPath}/logger`,
    level: 'debug',
    token: '76a8f24b971842c21628733e0f0a4068725efba7'
};

export {
    ajax,
    app,
    pdf,
    cache,

    logger,
};
