<template>
    <div class="app__block m--fixed-footer">
        <Header
            :step="1"
            :steps="3"
            :title="'Выберите номер'"
        />
        <div class="app__body rooms">
            <div class="rooms__top">
                <div class="rooms__params">
                    <div class="rooms__params-dates">
                        {{ order.range.start }} - {{ order.range.end }}
                    </div>
                    <div
                        :class="!order.data.rooms ? ' color-red' : ''"
                        class="rooms__params-rooms"
                    >
                        {{ $helpers.stringForNumber(order.data.rooms || 0, ['номер', 'номера', 'номеров']) }}, {{ $helpers.stringForNumber((order.data.people + order.data.children + order.data.teen) || 0, ['гость', 'гостя', 'гостей']) }}
                    </div>
                    <button
                        class="button m--blue rooms__params-button"
                        @click="prev()"
                    >
                        Изменить данные
                    </button>
                </div>
                <div
                    v-if="rooms.length"
                    class="rooms__included"
                >
                    <div class="rooms__included-title">В стоимость включено:</div>
                    <div class="rooms__included-item m--icon-spa">spa-центр</div>
                    <div class="rooms__included-item m--icon-lego">детская комната</div>
                    <div class="rooms__included-item m--icon-pills">бильярд</div>
                </div>
            </div>
            <template
                v-if="alertError"
            >
                <div
                    class="rooms__alert content"
                    v-html="alertError"
                />
            </template>
            <template
                v-else
            >
                <div
                    v-if="$store.state.loader"
                    class="rooms__block"
                >
                    <Loader />
                </div>
                <div
                    v-else-if="rooms.length"
                    class="rooms__block"
                >
                    <RoomsBlock
                        placeHeader="Номера"
                        :placeType=1
                        :rooms="rooms"
                        @getRooms="getRooms"
                        @calcAmount="calcAmount"
                        @next="next"
                    />
                    <RoomsBlock
                        placeHeader="Коттеджи"
                        :placeType=2
                        :rooms="rooms"
                        @getRooms="getRooms"
                        @calcAmount="calcAmount"
                        @next="next"
                    />
                </div>
                <div
                    v-else
                    class="rooms__block"
                >
                    <div class="rooms__alert m--no-margin">
                        Вашему запросу не соответствует ни один из наших номеров или коттеджей. Измените количество человек и номеров, потом повторите поиск.
                    </div>
                </div>
            </template>
        </div>
        <div class="app__footer basket">
            <BasketBlock
                :order="order"
                @next="next"
            />
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Loader from '@/components/loader.vue';
    import Header from '@/components/header';
    import BasketBlock from '@/components/basket-block';
    import RoomsBlock from '@/components/rooms-block';

    export default {
        components: {
            Loader,
            Header,
            BasketBlock,
            RoomsBlock,
        },
        data() {
            return {
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru') || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru') || new Date(),
                    },
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    amount: this.$store.state.amount,
                },
                rooms: [],
                alertError: null
            };
        },
        created() {
        },
        destroyed() {
        },
        mounted() {
            let query = this.$router.currentRoute.value.query;
            if (query._cmd === 'modal' || query._cmd === 'window') {
                let data = {
                    rooms: 1 * query.rooms || 1,
                    people: 1 * query.people || 1,
                    children: 1 * query.children || 0,
                    teen: 1 * query.teen || 0
                }
                this.order.range.start = query.dateStart;
                this.order.range.end = query.dateEnd;
                this.order.data = data;
                let params = {
                    date_start: this.$helpers.parseDate(this.order.range.start, 'DD.MM.YYYY'),
                    date_end: this.$helpers.parseDate(this.order.range.end, 'DD.MM.YYYY'),
                    data: data
                };
                this.$store.dispatch('updateData', params);
            }
            this.getRooms();
            this.$store.dispatch('hideError');
        },
        methods: {
            getRooms(params = null) {
                this.$store.dispatch('showLoader', { label: 'Загрузка данных по номерам' });
                if (params) {
                    this.order.range.start = params.date_start.toLocaleDateString('ru');
                    this.order.range.end = params.date_end.toLocaleDateString('ru');
                    this.order.data = params.data;
                    this.rooms = [];
                }
                if (this.order.range === null || (!this.order.range.start || !this.order.range.end || !this.order.data.rooms)) {
                    return false;
                }
                let filter = {
                    date_from: this.order.range.start,
                    date_to: this.order.range.end,
                    //tariff_code: 'завтрак',
                    people_count: this.order.data.people,
                    teen_count: this.order.data.teen || 0,
                    children_count: this.order.data.children || 0,
                };
                if (this.order.data.rooms > 1) {
                    filter.people_count = 1;
                    filter.teen_count = 0;
                    filter.children_count = 0;
                }
                app.getFreeRooms(filter).then(res => {
                    this.rooms = res;
                    let roomsFree = [];
                    this.rooms.forEach(room => {
                        roomsFree.push({
                            id: room.id,
                            free_rooms: room.free_rooms,
                            next_spaces: room.next_spaces
                        })
                        this.$store.dispatch('updateRooms', roomsFree);
                    });
                    this.$store.dispatch('hideLoader');
                }).catch(err => {
                    if (err.response.status === 400 && err.response.data && err.response.data.detail) {
                        this.alertError = err.response.data.detail;
                    } else {
                        this.$store.dispatch('showError', { err });
                    }
                    console.error(err);
                    this.$store.dispatch('hideLoader');
                });
            },
            calcAmount() {
                let amount = 0;
                this.order.items.forEach(item => {
                    amount = amount + item.price * 1;
                });
                this.$store.dispatch('updateOrderAmount', amount);
                this.order.amount = amount;
            },
            prev() {
                this.$router.push({ name: 'index' });
            },
            next() {
                this.calcAmount();
                if (this.order.items.length > 1) {
                    this.$router.push({ name: 'basket' });
                } else {
                    this.$router.push({ name: 'tariffs' });
                }
            },
        }
    };
</script>
