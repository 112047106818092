<template>
    <form
        class="rooms__row"
    >
        <div class="rooms__row-inner">
            <div class="rooms__row-info">
                <a 
                    v-if="room.page_url"
                    :href="room.page_url" 
                    class="rooms__row-name"
                    target="_blank"
                >
                    {{ room.name }}
                </a>
                <div 
                    v-else
                    class="rooms__row-name"
                >
                    {{ room.name }}
                </div>
                <div class="rooms__row-picture">
                    <div class="rooms__row-picture-inner">
                        <img 
                            :src="room.picture" 
                            alt="" 
                        >
                    </div>
                </div>
            </div>
            <div class="rooms__row-dates">
                <DatePicker 
                    v-model="range"
                    mode="date"
                    :masks="masks"
                    :min-date="new Date()"
                    :rows="$screens({ default: 1, sm: 1 })"
                    :columns="$screens({ default: 1, sm: 2 })"
                    is-range
                >
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="form__field field m--width-100">
                            <div class="field__label">
                                Дата заезда
                            </div>
                            <div class="field__input">
                                <input
                                    class="m--calendar"
                                    name="date_start"
                                    :value="inputValue.start"
                                    v-on="inputEvents.start"
                                >
                            </div>
                        </div>
                        <div class="form__field field m--width-100">
                            <div class="field__label">
                                Дата выезда
                            </div>
                            <div class="field__input">
                                <input
                                    class="m--calendar"
                                    :class="dateError ? 'is-error' : ''"
                                    name="date_end"
                                    :value="inputValue.end"
                                    v-on="inputEvents.end"
                                >
                            </div>
                        </div>
                    </template>
                </DatePicker>
                <button 
                    class="rooms__row-button button m--blue m--small"
                    @click.prevent="onToggleCalendar()"
                >
                    Доступность
                </button>
            </div>                    
            <div class="rooms__row-tariffs">
                <div class="form__field field m--width-100">
                    <div class="field__label">
                        Питание
                    </div>
                    <div 
                        v-for="titem in room.tariff_list"
                        :key="`tariff-${titem.id}`"
                        class="field__input"
                    >
                        <input
                            :id="`tariff-${room.id}-${titem.id}`"
                            v-model="tariff"
                            :value="titem.id"
                            type="radio"
                            name="tariff"
                            @change="change()"
                        >
                        <label 
                            :for="`tariff-${room.id}-${titem.id}`"
                        >
                            {{ titem.name }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="rooms__row-peoples">
                <div class="form__field field m--width-100">
                    <div class="field__label">
                        Количество взрослых
                    </div>
                    <div class="field__input">
                        <Multiselect
                            v-model="people.value"
                            :options="people.options"
                            class="m--person"
                            :class="peopleError ? 'is-error' : ''"
                            placeholder="старше 12 лет"
                            @select="select()"
                            @clear="clear('people')"
                        />
                    </div>
                </div>
                <div class="form__field field m--width-100">
                    <div class="field__label">
                        Количество детей
                    </div>
                    <div class="field__input">
                        <Multiselect
                            v-model="children.value"
                            :options="children.options"
                            class="m--person"
                            :class="peopleError ? 'is-error' : ''"
                            placeholder="дети до 6 лет"
                            @select="select()"
                            @clear="clear('children')"
                        />
                    </div>
                    <div class="field__input">
                        <Multiselect
                            v-model="teen.value"
                            :options="teen.options"
                            class="m--person"
                            :class="peopleError ? 'is-error' : ''"
                            placeholder="дети от 7 до 12 лет"
                            @select="select()"
                            @clear="clear('teen')"
                        />
                    </div>
                </div>
            </div>
            <div class="rooms__row-prices">
                <div class="form__field field m--width-100">
                    <div class="field__label">
                        Расчетная цена
                    </div>
                    <div class="rooms__row-prices-item">
                        <div class="rooms__row-prices-name">
                            Итого
                        </div>
                        <div class="rooms__row-prices-value">
                            <span>{{ $helpers.toPrice(price || 0) }}</span> <span class="m--currency">₽</span>
                        </div>
                    </div>
                    <div class="rooms__row-prices-item">
                        <div class="rooms__row-prices-name">
                            Скидка, %
                        </div>
                        <div class="rooms__row-prices-value">
                            <div class="field__input">
                                <input
                                    v-model="discount"
                                    name="discount"
                                    type="number"
                                    min="0"
                                    placeholder="%"
                                    @input="change()"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="rooms__row-prices-item">
                        <div class="rooms__row-prices-name">
                            Итого, с учетом скидки
                        </div>
                        <div class="rooms__row-prices-value">
                            <span>{{ $helpers.toPrice(price - discountSum || 0) }}</span> <span class="m--currency">₽</span>
                        </div>
                    </div>
                    <div class="rooms__row-prices-item">
                        <div class="rooms__row-prices-name">
                            Выгода клиента
                        </div>
                        <div class="rooms__row-prices-value">
                            <span>{{ $helpers.toPrice(discountSum || 0) }}</span> <span class="m--currency">₽</span>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-if="loadingData"
                class="rooms__row-loader"
            >
                <svg 
                    class="spinner" 
                    viewBox="0 0 50 50"
                >
                    <circle 
                        class="path" 
                        cx="25" 
                        cy="25" 
                        r="20" 
                        fill="none" 
                        stroke-width="4" 
                    />
                </svg>
            </div>
        </div>
        <div
            v-if="showCalendar"
            class="room__info-calendar calendar"
        >
            <div
                v-if="calendarLoader"
                class="rooms__block"
            >
                <Loader 
                    :propLoader="calendarLoader"
                />
            </div>
            <template
                v-else
            >
                <template
                    v-if="calendarRoomIdxs.length"
                >
                    <div
                        v-if="calendarRoomIdxs.length > 1"
                        class="calendar__variants"
                    >
                        <div class="calendar__variants-label">
                            Варианты:
                        </div>
                        <div class="calendar__variants-buttons">
                            <button
                                v-for="(variant, index) in calendarRoomIdxs"
                                :key="`variant-${index}`"
                                class="calendar__variants-button button m--blue-outline"
                                :class="index === calendarRoomIdx ? 'is-active' : ''"
                                @click.prevent="changeVariant(index)"
                            >
                                {{ index + 1 }}
                            </button>
                        </div>
                    </div>
                    <Splide
                        ref="calendar"
                        :options="calendarOptions"
                    >
                        <SplideSlide
                            v-for="(date, index) in calendarRoom[calendarRoomIdxs[calendarRoomIdx]]"
                            :key="`day-${index}`"
                            class="calendar__item"
                        >
                            <div class="calendar__item-day">
                                {{ date.weekday }}<br>
                                {{ date.date.split('.')[0] }} {{ monthStr[date.date.split('.')[1] - 1] }}
                            </div>
                            <div
                                v-if="date.free"
                                class="calendar__item-price m--no-cursor"
                            >
                                <span>{{ $helpers.toPrice(date.price) }}</span> <span class="m--currency">₽</span>
                            </div>
                            <div
                                v-else
                                class="calendar__item-price is-disabled"
                            />
                        </SplideSlide>
                    </Splide>
                </template>
                <template
                    v-else
                >
                    <div class="rooms__alert m--no-margin">
                        Данные по доступности отсутствуют
                    </div>
                </template>
            </template>
        </div>
    </form>
</template>

<script>
    import { app } from "@/services";
    import Loader from '@/components/loader.vue';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { DatePicker } from 'v-calendar';
    import Multiselect from '@vueform/multiselect'
    export default {
        components: {
            Loader,
            Multiselect,
            DatePicker,
            Splide,
            SplideSlide,
        },
        props: {
            item: {
                type: Object,
                default() { return {}; }
            },
            dates: {
                type: Object,
                default() { return {}; }
            },
        },
        data: function() {
            return {
                room: this.item,
                range: this.dates,
                masks: {
                    input: 'DD.MM.YYYY',
                },
                dateError: false,
                peopleError: false,
                people: {
                    value: 1,
                    options: [
                        {value: 1, label: '1 взрослый'},
                        {value: 2, label: '2 взрослых'},
                        {value: 3, label: '3 взрослых'},
                        {value: 4, label: '4 взрослых'},
                        {value: 5, label: '5 взрослых'},
                        {value: 6, label: '6 взрослых'},
                        {value: 7, label: '7 взрослых'},
                        {value: 8, label: '8 взрослых'},
                        {value: 9, label: '9 взрослых'},
                        {value: 10, label: '10 взрослых'},
                        {value: 11, label: '11 взрослых'},
                        {value: 12, label: '12 взрослых'},
                        {value: 13, label: '13 взрослых'},
                        {value: 14, label: '14 взрослых'},
                        {value: 15, label: '15 взрослых'},
                    ]
                },
                children: {
                    value: null,
                    options: [
                        {value: 1, label: '1 ребенок до 6 лет'},
                        {value: 2, label: '2 ребенка до 6 лет'},
                        {value: 3, label: '3 ребенка до 6 лет'},
                        {value: 4, label: '4 ребенка до 6 лет'},
                        {value: 5, label: '5 детей до 6 лет'},
                    ]
                },
                teen: {
                    value: null,
                    options: [
                        {value: 1, label: '1 ребенок от 7 до 12 лет'},
                        {value: 2, label: '2 ребенка от 7 до 12 лет'},
                        {value: 3, label: '3 ребенка от 7 до 12 лет'},
                        {value: 4, label: '4 ребенка от 7 до 12 лет'},
                        {value: 5, label: '5 детей от 7 до 12 лет'},
                    ]
                },
                tariff: null,
                price: 0,
                discount: 0,
                discountSum: 0,
                loadingData: false,
                showCalendar: false,
                calendarOptions: {
                    type        : 'slide',
                    rewind      : false,
                    start       : 0,
                    //width       : 800,
                    gap         : '0px',
                    pagination  : false,
                    fixedWidth  : 80,
                    //fixedHeight : 110,
                    //cover       : true,
                    focus       : 'center',
                    isNavigation: true,
                    arrowPath: ' ',
                    perMove: 7,
                },
                calendarRoom: {},
                calendarRoomIdx: 0, 
                calendarRoomIdxs: [],
                calendarLoader: null,
                monthStr: [
                    'янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
                ],
            };
        },
        computed: {
        },
        watch: {
            range(value) {
                this.dateError = false;
                if (value.start.toLocaleDateString('ru') == value.end.toLocaleDateString('ru')) {
                    this.dateError = true;
                } else {
                    this.getRoomTariff();
                    if (this.showCalendar) {
                        this.onToggleCalendar(true);
                    }
                }
            }
        },
        created() {
        },
        mounted() {
            this.room.peoples = {
                people: this.people.value,
                children: this.children.value,
                teen: this.teen.value,
            };
            this.change();
        },
        methods: {
            select() {
                this.peopleError = false;
                this.room.peoples = {
                    people: this.people.value,
                    children: this.children.value,
                    teen: this.teen.value,
                };
                if (this.people.value + this.children.value + this.teen.value > this.room.max_count) {
                    this.peopleError = true;
                }
                this.getPrice();
            },
            change() {
                if (!this.room.tariff) {
                    let tariff = this.room.tariff_list.filter(titem => { 
                        if (titem.base_tariff === true) { return titem; } 
                    })[0];
                    this.room.tariff = tariff;
                    this.tariff = tariff.id;
                } else {
                    let tariff = this.room.tariff_list.filter(titem => { 
                        if (titem.id === this.tariff) { return titem; } 
                    })[0];
                    if (!tariff) {
                        tariff = this.room.tariff_list.filter(titem => { 
                            if (titem.base_tariff === true) { return titem; } 
                        })[0];
                    }
                    this.room.tariff = tariff;
                    this.tariff = tariff.id;
                }
                this.getPrice();
            },
            clear(param) {
                this[param].value = null;
                this.select();
            },
            getRoomTariff() {
                let params = {
                    id: [this.room.id],
                    date_from: new Date(this.range.start).toLocaleDateString('ru'),
                    date_to: new Date(this.range.end).toLocaleDateString('ru')
                }
                this.loadingData = true;
                app.getTariffRoom(params).then(res => {
                    this.loadingData = false;
                    this.$store.dispatch('hideLoader');
                    console.log(res);
                    this.room = res[0];
                    this.room.peoples = {
                        people: this.people.value,
                        children: this.children.value,
                        teen: this.teen.value,
                    };
                    this.change();
                    this.getPrice();
                }).catch(err => {
                    this.loadingData = false;
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                    console.error(err);
                });
            },
            getPrice() {
                if (this.room.id && this.room.tariff) {
                    this.price = this.$order.orderRoomPrice(this.room);
                    this.discountSum = this.price * (this.discount / 100);
                }
            },
            onToggleCalendar(reload) {
                if (!reload) {
                    this.showCalendar = !this.showCalendar;
                }
                if (this.showCalendar === true || reload) {
                    let dateStart = new Date(this.range.start) || new Date();
                    let dateEnd = new Date(this.range.end) || new Date();
                    dateStart.setDate(dateEnd.getDate() - 30);
                    if (dateStart < new Date()) {
                        dateStart = new Date();
                    }
                    dateEnd.setDate(dateEnd.getDate() + 30);
                    let data = this.$store.state.data;
                    let params = {
                        date_from: dateStart.toLocaleDateString('ru'),
                        date_to: dateEnd.toLocaleDateString('ru'),
                        code_1c: this.room.code_1c,
                        people_count: data.people || 1,
                        teen_count: data.teen || 0,
                        children_count: data.children || 0
                    };
                    this.calendarLoader = { label: 'Загрузка доступности' };
                    app.getCalendarRoom(params).then(res => {
                        this.calendarLoader = null;
                        this.calendarRoom = res.free_rooms_list
                        this.calendarRoomIdxs = [];
                        Object.keys(this.calendarRoom).forEach(key => {
                            this.calendarRoomIdxs.push(key);
                        });
                        this.calcRange(0);
                    }).catch(err => {
                        this.calendarLoader = null;
                        this.$store.dispatch('showError', { err });
                        console.error(err);
                    });
                }
            },
            changeVariant(index) {
                this.calendarRoomIdx = index;
                this.calcRange(index);
            },
            calcRange(index) {
                if (!this.calendarRoomIdxs.length) {
                    return false;
                }
                this.availableDates = [];
                /*
                this.range = {
                    start: null,
                    end: null
                };
                */
                let start = null;
                let end = null;
                let days = this.calendarRoom[this.calendarRoomIdxs[index]].length;
                this.calendarRoom[this.calendarRoomIdxs[index]].forEach((date, idx) => {
                    if (date.free) {
                        if (!start) {
                            start = this.$helpers.parseDate(date.date, 'DD.MM.YYYY');
                        } else {
                            end = this.$helpers.parseDate(date.date, 'DD.MM.YYYY');
                        }
                    }
                    if (start && ((end && !date.free) || (end && days === idx + 1))) {
                        this.availableDates.push({
                            start: start,
                            end: end
                        });
                        start = null;
                        end = null;
                    }
                });
            },
        }
    };
</script>
