<template>
    <div class="app__block m--fixed-footer">
        <Header
            :step="2"
            :steps="3"
            :title="'Корзина. Шаг 2'"
            :back="'Вернуться к шагу 1'"
            @prev="prev"
        />
        <div class="app__body rooms">
            <template 
                v-if="rooms.length"
                class="rooms__block"
            >
                <div 
                    v-if="room"
                    class="rooms__block"
                >
                    <div
                        v-for="(room, index) in order.items"
                        :key="`room${index}-${room.id}`"
                        class="rooms__block-inner"
                    >
                        <TariffsRoomsItem 
                            :index="index"
                            :room="rooms.filter(item => { if (item.id == room.id) { return item; } })[0]"
                            :hideMore=true
                            :persons=false
                            @getTariffRoom="getTariffRoom"
                        />

                        <div class="room__tariffs tariffs">
                            <template
                                v-if="rooms.filter(item => { if (item.id == room.id) { return item; } })[0].tariff_list"
                            >
                                <h2 class="tariffs__title h2">Тарифы</h2>
                                <div
                                    class="tariffs__block"
                                >
                                    <TariffsFoodItem
                                        v-for="tariff in rooms.filter(item => { if (item.id == room.id) { return item; } })[0].tariff_list.filter(item => { if (item.base_tariff == false) { return item; } })"
                                        :key="tariff.id"
                                        :id="tariff.id"
                                        :index="index"
                                        :tariff="tariff"
                                        :selected="(order.items[index].tariff.id == tariff.id) ? true : false"
                                        @tariffSelect="tariffSelect"
                                    />
                                </div>
                            </template>
                            <h2 class="tariffs__title h2">Дополнительные услуги</h2>
                            <div 
                                v-if="room.options"
                                class="tariffs__advanced"
                            >
                                <TariffsAdvancedItem
                                    v-for="item in rooms.filter(item => { if (item.id == room.id) { return item; } })[0].options"
                                    :key="item.id"
                                    :index="index"
                                    :tariff="item"
                                    :option="order.items[index].options.filter(option => { if ( option.id == item.id) { return option; } })[0] || {}"
                                    @tariffAdvancedChange="tariffAdvancedChange"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="offersLoader"
                    class="tariffs__specials specials"
                >
                    <Loader 
                        :propLoader="offersLoader"
                    />
                </div>
                <template
                    v-else
                >
                    <div 
                        v-if="tariffs_specials.length"
                        class="tariffs__specials specials"
                    >
                        <h2 class="specials__title h2">Специальные предложения</h2>
                        <div class="specials__block">
                            <TariffsSpecialsItem
                                v-for="(item, index) in tariffs_specials"
                                :key="index"
                                :tariff="item"
                                :selected="item.id === offerSelectedId ? true : false"
                                :disabled="(item.min_days === 0 || item.min_days <= deltaDate) ? false : true"
                                @tariffSpecialsCheck="tariffSpecialsCheck"
                            />
                        </div>
                    </div>
                </template>
            </template>
            <template
                v-else
            >
                <div
                    v-if="$store.state.loader"
                    class="rooms__block"
                >
                    <Loader />
                </div>
                <div 
                    v-else
                    class="rooms__block"
                >
                    Ничего не найдено
                </div>
            </template>
        </div>
        <div class="app__footer basket">
            <BasketBlock
                :order="order"
                @next="next"
            />
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Loader from '@/components/loader.vue';
    import Header from '@/components/header';
    import BasketBlock from '@/components/basket-block';
    import TariffsRoomsItem from '@/components/tariffs-rooms-item';
    import TariffsFoodItem from '@/components/tariffs-food-item';
    import TariffsAdvancedItem from '@/components/tariffs-advanced-item';
    import TariffsSpecialsItem from '@/components/tariffs-specials-item';

    export default {
        components: {
            Loader,
            Header,
            BasketBlock,
            TariffsRoomsItem,
            TariffsFoodItem,
            TariffsAdvancedItem,
            TariffsSpecialsItem,
        },
        data() {
            return {
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru') || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru') || new Date(),
                    },
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    offer: this.$store.state.offer,
                    amount: this.$store.state.amount,
                },
                room: null,
                rooms: [
                ],
                tariffSelectedId: null,
                tariffs_specials: [],
                offersLoader: null,
                offerSelectedId: null,
                deltaDate: null
            };
        },
        created() {
            this.getTariffRoom(this.order.items);
            this.offerSelectedId = (this.$store.state.offer) ? this.$store.state.offer.id : null;
            this.deltaDate = Math.abs(new Date(this.$store.state.dateEnd) - new Date(this.$store.state.dateStart));
            this.deltaDate = Math.ceil(this.deltaDate / (1000 * 60 * 60 * 24));
        },
        destroyed() {
        },
        mounted() {
        },
        methods: {
            getTariffRoom(items) {
                let ids = items.map(item => {
                    return item.id;
                });
                if (!ids) {
                    throw new Error('Не определены ID номера/номеров');
                } else {
                    this.$store.dispatch('showLoader', { label: 'Загрузка данных по номерам' });
                    let params = {
                        id: ids,
                        date_from: this.order.range.start,
                        date_to: this.order.range.end,
                    };
                    app.getTariffRoom(params).then(res => {
                        this.$store.dispatch('hideLoader');
                        this.rooms = res;
                        this.room = this.rooms[0];
                        //this.calcAmount();
                        this.order.items.forEach(item => {
                            console.log(item);
                            let room = this.rooms.filter(room => {
                                if (item.id === room.id) { return room; }
                            })[0];
                            console.log(room);
                            if (!item.tariff) {
                                let tariff = room.tariff_list.filter(titem => { 
                                    if (titem.base_tariff === true) { return titem; } 
                                })[0];
                                item.tariff = tariff
                                this.$store.dispatch('updateOrderItems', this.order.items);
                            } else {
                                let tariff = room.tariff_list.filter(titem => { 
                                    if (titem.id === item.tariff.id) { return item; } 
                                })[0];
                                console.log(tariff);
                                this.tariffSelectedId = tariff.id;
                            }

                        });
                        this.calcAmount();
                    }).catch(err => {
                        this.$store.dispatch('hideLoader');
                        console.error(err);
                        this.$store.dispatch('showError', { err });
                    });
                    this.offersLoader = { label: 'Загрузка спец.предложений' };
                    app.getOffers().then(res => {
                        this.offersLoader = null;
                        this.tariffs_specials = res;
                    }).catch(err => {
                        this.offersLoader = null;
                        console.error(err);
                        this.$store.dispatch('showError', { err });
                    });
                }
            },
            tariffSelect(params) {
                console.log('Index: ', params.index);
                let item = this.order.items[params.index];
                let room = this.rooms.filter(room => { if (room.id === item.id) { return room; } })[0];
                this.tariffSelectedId = params.id;
                console.log(this.tariffSelectedId);
                let tariff = Object.assign({}, 
                    room.tariff_list.filter(item => { if (item.id === params.id) { return item; } })[0] ||
                    room.tariff_list.filter(item => { if (item.base_tariff === true) { return item; } })[0]
                );
                item.tariff = tariff
                this.$store.dispatch('updateOrderItems', this.order.items);
                this.calcRoomPrice(item);
            },
            tariffAdvancedChange(params, id) {
                console.log('Index: ', params.index);
                let item = this.order.items[params.index];
                let room = this.rooms.filter(room => { if (room.id === item.id) { return room; } })[0];
                console.log('Name: ', item.name);
                let options = item.options;
                let option = options.filter(option => { if ( option.id === params.id) { return option; } })[0];
                if (!option) {
                    option = Object.assign({}, room.options.filter(option => { if ( option.id === params.id) { return option; } })[0]);
                }
                console.log(option);
                let count = option.count || 0;
                count = count + params.direction;
                if (count < 0) { count = 0; }
                console.log('Count:', count);
                option.count = count;

                let idx = options.map(function(e) { return e.id }).indexOf(params.id);
                if (idx == -1) {
                    options.push(option);
                } else {
                    if (option.count > 0) {
                        options[idx] = option;
                    } else {
                        options.splice(idx, 1);
                    }
                }
                this.$store.dispatch('updateOrderItems', this.order.items);
                this.calcAmount();
            },
            tariffSpecialsCheck(params) {
                if (this.offerSelectedId === params.id) {
                    this.offerSelectedId = null;
                    this.$store.dispatch('updateOffer', null);
                    this.order.offer = null;
                } else {
                    this.offerSelectedId = params.id;
                    let offer = this.tariffs_specials.filter(item => { if ( item.id === params.id) { return item; } })[0];
                    this.$store.dispatch('updateOffer', offer);
                    this.order.offer = offer;
                }
                this.calcAmount();
            },
            calcRoomPrice(item) {
                item.price = this.$order.orderRoomPrice(item);
                this.calcAmount();
            },
            calcAmount() {
                let amount = this.$order.orderAmount(this.order);
                this.$store.dispatch('updateOrderAmount', amount);
                this.order.amount = amount;
            },
            prev() {
                this.$router.push({ name: 'basket' });
            },
            next() {
                this.$router.push({ name: 'order' });
            },
        }
    };
</script>
