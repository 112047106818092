<template>
    <div
        class="room m--light"
        :class="(moreOpened) ? 'is-opened' : ''"
    >
        <div class="room__inner">
            <div class="room__block">
                <div class="room__gallery">
                    <div class="room__gallery-picture">
                        <div class="room__gallery-picture-inner">
                            <Splide
                                v-if="room.picture || (room.photos && room.photos.length)"
                                :options="splideOptions"
                            >
                                <SplideSlide
                                    :key="`${room.id}-pic-0`"
                                >
                                    <a
                                        :data-src="room.picture"
                                        data-fancybox="gallery-comfort"
                                        data-preload="false"
                                    >
                                        <img
                                            :src="room.picture"
                                            alt=""
                                        >
                                    </a>
                                </SplideSlide>
                                <SplideSlide
                                    v-for="(photo, index) in room.photos"
                                    :key="`${room.id}-pic-${index + 1}`"
                                >
                                    <a
                                        :data-src="photo"
                                        data-fancybox="gallery-comfort"
                                        data-preload="false"
                                    >
                                        <img
                                            :src="photo"
                                            alt=""
                                        >
                                    </a>
                                </SplideSlide>
                            </Splide>
                        </div>
                    </div>
                </div>
                <div class="room__info">
                    <div class="room__info-main">
                        <div class="room__info-params m--row m--start">
                            <div class="room__info-params-dates">
                                {{ order.range.start }} - {{ order.range.end }}
                            </div>
                            <div class="room__info-params-rooms">
                                {{ $helpers.stringForNumber(order.data.rooms, ['номер', 'номера', 'номеров']) }}, {{ $helpers.stringForNumber((order.items[index].peoples.people + order.items[index].peoples.teen + order.items[index].peoples.children), ['гость', 'гостя', 'гостей']) }}
                            </div>
                        </div>
                        <div class="room__info-block m--no-border m--row m--no-max-width">
                            <div class="room__info-name">
                                {{ room.name }}
                            </div>
                            <div class="room__info-price m--blue">
                                <span>{{ $helpers.toPrice(room.price || 0) }}</span> <span class="m--currency">₽</span>
                            </div>
                        </div>
                        <div class="room__info-block m--no-border m--row">
                            <div class="room__info-params m--row">
                                <div class="room__info-param m--area">
                                    {{ room.area }} кв.м
                                </div>
                                <div class="room__info-param m--persons">
                                    до {{ $helpers.stringForNumber((room.place_count + room.extra_place_count), ['человека', 'человек', 'человек']) }}
                                </div>
                                <div class="room__info-param m--breakfast">
                                    {{ order.items[index] ? order.items[index].tariff.name : room.included }}
                                </div>
                            </div>
                            <!--
                            <div class="room__info-prices">
                                <div class="room__info-price m--big"><span>4 900</span> <span class="m--currency">₽</span></div>
                            </div>
                            -->
                        </div>

                        <div
                            v-if="!hideMore"
                            class="room__info-description content"
                        >
                            <p v-if="room.content_order">
                                {{ room.content_order }}
                            </p>
                            <p>Изменение даты заезда/выезда на этом этапе невозможно.</p>
                        </div>
                    </div>

                    <div class="room__buttons">
                        <button
                            v-if="!hideMore"
                            class="room__button-more button m--blue-outline m--small"
                            @click.prevent="openMore()"
                        >
                            Подробнее
                        </button>
                        <div class="room__buttons-upgrade">
                            <div class="room__buttons-price m--delta">
                                <span>{{ (room.price - order.items[index].price) > 0 ? '+' : '' }}{{ $helpers.toPrice(room.price - order.items[index].price) }}</span> <span class="m--currency">₽</span>
                            </div>
                            <button
                                class="room__button-upgrade button m--blue m--small"
                                @click.prevent="upgradeItem(index)"
                            >
                                Повысить комфорт
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!hideMore"
                class="room__more">
                <div
                    v-html="room.content_base"
                    class="room__more-description content"
                >
                </div>
                <div class="room__params">
                    <div
                        v-for="(block, iblock) in room.specifications"
                        :key="`block-${iblock}`"
                        class="room__params-block"
                    >
                        <div class="room__params-title">{{ block.name }}</div>

                        <div
                            v-for="(param, iparam) in block.specificationsblocks"
                            :key="`param-${iblock}-${iparam}`"
                            :class="`m--${param.class_name}`"
                            class="room__param"
                        >
                            {{ param.value }}
                        </div>
                    </div>
                </div>
                <div class="room__more-buttons">
                    <a
                        href="#"
                        class="room__more-close"
                        @click.prevent="closeMore()"
                    >
                        Свернуть описание
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    export default {
        components: {
            Splide,
            SplideSlide,
        },
        props: {
            id: {
                type: Number,
                default() { return null; }
            },
            index: {
                type: Number,
                default() { return 0; }
            },
            type: {
                type: String,
                default() { return ''; }
            },
            hideMore: {
                type: Boolean,
                default() { return false; }
            },
            persons: {
                type: Boolean,
                default() { return true; }
            },
            room: {
                type: Object,
                default() { return {}; }
            },
        },
        data: function() {
            return {
                order: {
                    range: {
                        start: new Date(this.$store.state.dateStart).toLocaleDateString('ru') || new Date(),
                        end: new Date(this.$store.state.dateEnd).toLocaleDateString('ru') || new Date(),
                    },
                    //rooms: this.$store.state.rooms,
                    data: this.$store.state.data,
                    items: this.$store.state.items,
                    amount: this.$store.state.amount,
                },
                moreOpened: false,
                splideOptions: {
                    arrows: true,
                    //fixedHeight: '100%',
                    //cover: true
                }
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            openMore() {
                this.moreOpened = true;
            },
            closeMore() {
                this.moreOpened = false;
            },
            upgradeItem(index) {
                this.$emit('upgradeItem', index);
            }
        }
    };
</script>
