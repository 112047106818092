<template>
    <div
        v-if="!!loader"
        class="loader"
    >
        <div class="loader__spinner">
            <svg class="spinner" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </div>
        <div class="loader__label">
            {{ loaderLabel }}
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            loader() {
                return this.$store.state.loader || this.propLoader;
            },
            loaderLabel() {
                return this.loader && this.loader.label;
            }
        },
        props: {
            propLoader: {
                type: Object,
                default() { return null; }
            },
        }
    };
</script>
