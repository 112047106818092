<template>
    <div class="tariffs__advanced-item">
        <div class="tariffs__advanced-item-name">
            {{ tariff.name }}
        </div>
        <div class="tariffs__advanced-item-info">
            {{ tariff.info }}
        </div>
        <div class="tariffs__advanced-item-price">
            {{ $helpers.toPrice(tariff.value, {sign: '₽'}) }}
        </div>
        <div class="tariffs__advanced-item-count">
            <button 
                class="button m--blue-outline tariffs__advanced-item-button m--minus"
                :disabled="!option.count ? 1 : 0"
                @click.prevent="change(tariff.id, -1)"
            >
                -
            </button>
            <div class="tariffs__advanced-item-nums">
                {{ option.count || 0 }}
            </div>
            <button 
                class="button m--blue tariffs__advanced-item-button m--plus"
                @click.prevent="change(tariff.id, 1)"
            >
                +
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            index: {
                type: Number,
                default() { return null; }
            },
            tariff: {
                type: Object,
                default() { return {}; }
            },
            option: {
                type: Object,
                default() { return {}; }
            }
        },
        data: function() {
            return {
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            change(id, direction) {
                let params = {
                    id: id,
                    index: this.index,
                    direction: direction
                }
                this.$emit('tariffAdvancedChange', params);
            }
        }
    };
</script>
